import axios from 'axios'
import config from '../config';
import store from '../store'
import router from '../router';

const api = axios.create({
    baseURL: config.API_URL
})

const failedRequest = [];

api.interceptors.request.use((request) => {
    return request;
})

api.interceptors.response.use(
    response => response,
    error => {
        const errorStatus = error.response.status;
        const isRefreshTokenState = store.getters['backend_auth/isRefreshTokenState'];

        failedRequest.push(error.config);

        // check if error is unauthenticated and not in refreshing token state
        if(errorStatus === 401 && !isRefreshTokenState){

            // check if refresh token is available
            const refresh_token = store.getters['backend_auth/refreshToken'];
            if(refresh_token && (refresh_token !== 'undefined')){
                ('MASUK SINI',refresh_token)
                store.dispatch('backend_auth/setRefreshTokenState',true);
                store.dispatch('backend_auth/refreshToken').then((data) => {
                    store.dispatch('backend_auth/setRefreshTokenState',false);
                    if(data.errors){
                        router.push({name:'Login'}).catch(()=>{});
                        return Promise.reject(error);
                    }
                    

                    const previousRequest = error.config;
                    previousRequest.headers['Authorization'] = 'Bearer ' + store.getters['backend_auth/accessToken'];
                    return axios.request(previousRequest);
                })
                return;
            }

            // if refresh token is not available we should redirect to login page
            return router.push({name:'Login'}).catch(()=>{});
        }

        return Promise.reject(error);
    }
)

export default {
    // AUTH
    login(data){
        return api.post('login',data);
    },
    refreshToken(data){
        return api.post('refreshToken',{'refresh_token': data});
    },
    logout(){
        api.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['backend_auth/accessToken'];
        return api.post('logout'); 
    },
    
    // PROFILE
    getProfile(){
        api.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['backend_auth/accessToken'];
        return api.get('profile');
    },
    updateProfile(data){
        api.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['backend_auth/accessToken'];
        return api.patch('profile/updateProfile',data);
    },
    updatePassword(data){
        api.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['backend_auth/accessToken'];
        return api.patch('profile/updatePassword',data);
    },

    // USER
    getUser(){
        api.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['backend_auth/accessToken'];
        return api.get('user');
    },
    addUser(data){
        api.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['backend_auth/accessToken'];
        return api.post('user',data);
    },
    updateUser(id,data){
        api.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['backend_auth/accessToken'];
        return api.patch('user/'+id,data);
    },
    deleteUser(id){
        api.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['backend_auth/accessToken'];
        return api.delete('user/'+id);
    },

    // CATEGORY
    getCategory(){
        api.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['backend_auth/accessToken'];
        return api.get('category');
    },
    addCategory(data){
        api.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['backend_auth/accessToken'];
        return api.post('category',data);
    },
    updateCategory(id,data){
        api.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['backend_auth/accessToken'];
        return api.patch('category/'+id,data);
    },
    deleteCategory(id){
        api.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['backend_auth/accessToken'];
        return api.delete('category/'+id);
    },

    // MENU
    getMenu(){
        api.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['backend_auth/accessToken'];
        return api.get('menu');
    },
    addMenu(data){
        api.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['backend_auth/accessToken'];
        return api.post('menu',data);
    },
    updateMenu(id,data){
        api.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['backend_auth/accessToken'];
        return api.patch('menu/'+id,data);
    },
    deleteMenu(id){
        api.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['backend_auth/accessToken'];
        return api.delete('menu/'+id);
    },

    // TESTIMONIAL
    getTestimonial(){
        api.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['backend_auth/accessToken'];
        return api.get('testimonial');
    },
    addTestimonial(data){
        api.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['backend_auth/accessToken'];
        return api.post('testimonial',data);
    },
    updateTestimonial(id,data){
        api.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['backend_auth/accessToken'];
        return api.patch('testimonial/'+id,data);
    },
    deleteTestimonial(id){
        api.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['backend_auth/accessToken'];
        return api.delete('testimonial/'+id);
    },

    // GALLERY
    getGallery(){
        api.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['backend_auth/accessToken'];
        return api.get('gallery');
    },
    addGallery(data){
        api.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['backend_auth/accessToken'];
        return api.post('gallery',data);
    },
    updateGallery(id,data){
        api.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['backend_auth/accessToken'];
        return api.patch('gallery/'+id,data);
    },
    deleteGallery(id){
        api.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['backend_auth/accessToken'];
        return api.delete('gallery/'+id);
    },

    // TEAM
    getTeam(){
        api.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['backend_auth/accessToken'];
        return api.get('team');
    },
    addTeam(data){
        api.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['backend_auth/accessToken'];
        return api.post('team',data);
    },
    updateTeam(id,data){
        api.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['backend_auth/accessToken'];
        return api.patch('team/'+id,data);
    },
    deleteTeam(id){
        api.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['backend_auth/accessToken'];
        return api.delete('team/'+id);
    },

    // CONTACT
    getContact(){
        api.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['backend_auth/accessToken'];
        return api.get('contact');
    },
    updateContact(id,data){
        api.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['backend_auth/accessToken'];
        return api.patch('contact/'+id, data);
    }
}