import Vue from 'vue'
import Vuex from 'vuex'

import menu from './modules/frontend/menu';
import testimonial from './modules/frontend/testimonial';
import gallery from './modules/frontend/gallery';
import team from './modules/frontend/team';
import contact from './modules/frontend/contact';

import backend_auth from './modules/backend/backend_auth';
import backend_config from './modules/backend/backend_config';
import backend_profile from './modules/backend/backend_profile';
import backend_user from './modules/backend/backend_user';
import backend_menu from './modules/backend/backend_menu';
import backend_category from './modules/backend/backend_category';
import backend_testimonial from './modules/backend/backend_testimonial';
import backend_gallery from './modules/backend/backend_gallery';
import backend_team from './modules/backend/backend_team';
import backend_contact from './modules/backend/backend_contact';

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({

  modules: {
    menu, testimonial, gallery, team, contact,
    backend_auth, backend_config, backend_profile, backend_user, backend_menu, backend_category, backend_testimonial, backend_gallery, backend_team, backend_contact
  }

})
