import api from "../../../api/api";

// initial state
const state = () => ({
	menu: []
})

// getters	
const getters = {
	searchMenu: (state) => (value, category) => {
		const searchValue = value.toString().toLowerCase();
		function deepSearch(item){
			return Object.keys(item).some(obj => {
				if(item[obj] && obj !== 'imgId' && obj !== 'id' && obj !== 'position'){
					return typeof item[obj] === 'object' ? deepSearch(item[obj]) : item[obj].toString().toLowerCase().includes(searchValue);
				}
			})
		}

		let menuList = state.menu;
		if(category){ 
			menuList = menuList.filter(item => item.category.id == category);
		}

		const searchResult = menuList.filter(item => {
			return deepSearch(item);
		})
		return searchResult;
	},

	searchCategory: (state) => (value) => {
		const searchValue = value.toString().toLowerCase();
		function deepSearch(item){
			return Object.keys(item).some(obj => {
				if(item[obj] && obj !== 'imgId' && obj !== 'id' && obj !== 'position'){
					return typeof item[obj] === 'object' ? deepSearch(item[obj]) : item[obj].toString().toLowerCase().includes(searchValue);
				}
			})
		}
		const searchResult = state.category.filter(item => {
			return deepSearch(item);
		})
		return searchResult;
	}
}

// actions
const actions = {
	getMenu({commit}){
		return new Promise((resolve,reject) => {
			api.getMenu().then(response => {
				commit('SET_MENU', response.data)
				resolve({'success':response.data});
			}).catch(error => {
				const errorResponse = error && error.response && error.response.data;
				resolve({'errors':errorResponse && errorResponse.message});
			})
		})
	},

	addMenu({commit},menu){
		return new Promise((resolve,reject) => {
			api.addMenu(menu).then(response => {
				commit('ADD_MENU', response.data.data)
				resolve(response.data);
			}).catch(error => {
				const errorResponse = error && error.response && error.response.data;
				resolve({'errors':errorResponse && errorResponse.message});
			})
		})
	},

	updateMenu({commit},menu){
		return new Promise((resolve,reject) => {
			api.updateMenu(menu.id,menu).then(response => {
				commit('UPDATE_MENU', response.data.data)
				resolve(response.data);
			}).catch(error => {
				const errorResponse = error && error.response && error.response.data;
				resolve({'errors':errorResponse && errorResponse.message});
			})
		})
	},

	deleteMenu({commit},id){
		return new Promise((resolve,reject) => {
			api.deleteMenu(id).then(response => {
				commit('DELETE_MENU', id)
				resolve(response.data);
			}).catch(error => {
				const errorResponse = error && error.response && error.response.data;
				resolve({'errors':errorResponse && errorResponse.message});
			})
		})
	}  
}

// mutations
const mutations = {
	SET_MENU(state, menu){
		state.menu = menu;
	},

	ADD_MENU(state, menu){
		state.menu.unshift(menu);
	},

	UPDATE_MENU(state, menu){
		const updateIndex = state.menu.findIndex((data) => data	.id === menu.id);
		state.menu.splice(updateIndex,1,menu);
	},

	DELETE_MENU(state, id){
		const deleteIndex = state.menu.findIndex((data) => data.id === id);
		state.menu.splice(deleteIndex, 1);
	}
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}