<template>
	<v-main class="h-100 pa-0 p-absolute overflow-hidden" ref="main-application">

    <Loader></Loader>
    
    <v-app-bar app elevate-on-scroll :inverted-scroll="$vuetify.breakpoint.mdAndUp" color="mou-primary" height="90" style="z-index:8;">
        <v-app-bar-nav-icon v-if="$vuetify.breakpoint.xs" @click="drawer = true" style="z-index:8"></v-app-bar-nav-icon>
        <v-flex v-if="$vuetify.breakpoint.smAndUp" class="w-100 z-index-2 p-absolute py-2 montserrat fw-600 mou-caption-1" 
            :class="$vuetify.breakpoint.smAndDown ? 'pr-10' : 'px-10'"
            style="height:90px;top: 0;"
        >
          <v-row class="d-flex align-center">
            <v-col cols="auto" :class="$vuetify.breakpoint.mdAndUp ? 'px-5' : 'pr-3'">
              <span class="borderLeftRight mou-normal--text cursor-pointer" @click="goTo('page-jumbotron')">Home</span>
            </v-col>
            <v-col cols="auto" :class="$vuetify.breakpoint.mdAndUp ? 'px-5' : 'px-3'">
              <span class="borderLeftRight mou-normal--text cursor-pointer" @click="goTo('page-about')">About Us</span>
            </v-col>
            <v-col cols="auto" :class="$vuetify.breakpoint.mdAndUp ? 'px-5' : 'px-3'">
              <span class="borderLeftRight mou-normal--text cursor-pointer" @click="goTo('page-menu')">Menus</span>
            </v-col>
            <v-col cols="auto" :class="$vuetify.breakpoint.mdAndUp ? 'px-5' : 'px-3'">
              <span class="borderLeftRight mou-normal--text cursor-pointer" @click="goTo('page-lab')">The Labs</span>
            </v-col>
            <v-col cols="auto" :class="$vuetify.breakpoint.mdAndUp ? 'px-5' : 'px-3'">
              <span class="borderLeftRight mou-normal--text cursor-pointer" @click="goTo('page-location')">Contact Us</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto" :class="$vuetify.breakpoint.mdAndUp ? 'px-5' : 'px-1'">
              <v-img
                            src="/assets/logo/logo-black.png"
                width="100px"
                height="auto"
                contain eager
              >

              </v-img>
            </v-col>
          </v-row>

        </v-flex>

        <v-row class="p-absolute w-100" v-if="$vuetify.breakpoint.xs">
          <v-col cols="12" class="d-flex align-center justify-center">
            <v-img
              src="/assets/logo/logo-black.png"
              width="100px"
              height="50px"
              contain eager
            ></v-img>
          </v-col>
        </v-row>
    </v-app-bar>

    <v-navigation-drawer width="100%" class="mou-primary-dark" v-model="drawer" app v-if="$vuetify.breakpoint.smAndDown" style="z-index:9;">
      <v-row class="playfair fw-700 mou-menu pa-5 text-center">
        <v-col cols="12" class="text-right">
          <v-btn text icon x-large @click.stop="drawer = !drawer"><v-icon>mdi mdi-close</v-icon></v-btn>
        </v-col>
        <v-col cols="12">
          <span class="borderLeftRight mou-normal--text cursor-pointer" @click="goTo('page-jumbotron')">Home</span>
        </v-col>
        <v-col cols="12">
          <span class="borderLeftRight mou-normal--text cursor-pointer" @click="goTo('page-about')">About Us</span>
        </v-col>
        <v-col cols="12">
          <span class="borderLeftRight mou-normal--text cursor-pointer" @click="goTo('page-menu')">Menus</span>
        </v-col>
        <v-col cols="12">
          <span class="borderLeftRight mou-normal--text cursor-pointer" @click="goTo('page-lab')">The Labs</span>
        </v-col>
        <v-col cols="12">
          <span class="borderLeftRight mou-normal--text cursor-pointer" @click="goTo('page-location')">Contact Us</span>
        </v-col>
      </v-row>
    </v-navigation-drawer>
		
		<Jumbotron id="page-jumbotron" class="z-index-1 p-relative"></Jumbotron>
		<About id="page-about" class="z-index-5 p-relative"></About>
		<Menu id="page-menu" class="z-index-5 p-relative"></Menu>
		<Parallax id="page-parallax" class="z-index-5 p-relative"></Parallax>
		<Testimonial id="page-testimonial" class="z-index-5 p-relative"></Testimonial>
		<Lab id="page-lab" class="z-index-5 p-relative"></Lab>
		<Team id="page-team" class="z-index-5 p-relative"></Team>
		<Visit id="page-visit" class="z-index-5 p-relative"></Visit>
		<Location id="page-location" ref="test2" class="z-index-5 p-relative"></Location>
		<InstagramFeed id="page-instagramFeed" class="z-index-5 p-relative"></InstagramFeed>
		<Footer id="page-footer" class="z-index-5 p-relative"></Footer>

  </v-main>
</template>

<script>
  import Loader from '../../components/Frontend/Home/Loader';
  import Jumbotron from '../../components/Frontend/Home/Page-Jumbotron';
  import About from '../../components/Frontend/Home/Page-About';
  import Menu from '../../components/Frontend/Home/Page-Menu';
  import Parallax from '../../components/Frontend/Home/Page-Parallax';
  import Testimonial from '../../components/Frontend/Home/Page-Testimonial';
  import Lab from '../../components/Frontend/Home/Page-Lab';
  import Team from '../../components/Frontend/Home/Page-Team';
  import Visit from '../../components/Frontend/Home/Page-Visit';
  import Location from '../../components/Frontend/Home/Page-Location';
  import InstagramFeed from '../../components/Frontend/Home/Page-InstagramFeed';
  import Footer from '../../components/Frontend/Home/Page-Footer';

  import * as easings from 'vuetify/es5/services/goto/easing-patterns'


  export default {
	  metaInfo: {
      title: 'Mou Gelato',
      titleTemplate: '%s | Móu - more',
      meta: [
        { name: 'description', content: 'We believe that gelato, just like any other true food, needs to be served fresh. We want to bring authentic artisan gelato, keeping real flavors of the Italian gelato alive each and every day by serving fresh gelato.'},
        { property: 'og:locale', content: "en_US"},
        { property: 'og:type', content: 'website'},    
        { property: 'og:title', content: "Mou Gelato | Mou - more"},
        { property: 'og:description', content: 'We believe that gelato, just like any other true food, needs to be served fresh. We want to bring authentic artisan gelato, keeping real flavors of the Italian gelato alive each and every day by serving fresh gelato.'},
        { property: 'og:url', content: 'https://www.mougelato.com/'},
        { property: 'og:image', content: 'https://mougelato.com/assets/logo/logo-color.png'},
        { property: 'og:site_name', content: 'Mou Gelato'},
        { name: 'robots', content: 'index,follow'} 
      ],
      link: [
        {rel: 'canonical', href: 'https://www.mougelato.com'}
      ]
	  },
	
    components: {
      Loader, Jumbotron, About, Menu, Parallax, Testimonial, Lab, Team,
	    Visit, Location, InstagramFeed, Footer
    },
  
    data: () => ({
      drawer: null,
	  }),

    methods: {
      goTo(targetRef){

        const scrollOption = {
          duration: 1000,
          offset: this.$vuetify.breakpoint.smAndDown ? 0 : 90,
          easing: 'easeInOutCubic',
        }

        this.$vuetify.goTo('#'+targetRef, scrollOption)
        this.drawer = null;
      },
    },

}
</script>