import api from "../../../api/frontend-api";

// initial state
const state = () => ({
	contact: {}
})

// getters
const getters = {}

// actions
const actions = {
	getContact({commit,state}){
		return new Promise((resolve,reject) => {
			api.getContact().then(response => {
				commit('SET_CONTACT', response.data[0])
				resolve({'success':response.data[0]});
			}).catch(error => {
				const errorResponse = error && error.response && error.response.data;
				resolve({'errors':errorResponse && errorResponse.message});
			})
		})
	},
}

// mutations
const mutations = {
	SET_CONTACT(state, contact){
		state.contact = contact;
	},
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}