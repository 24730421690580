import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Frontend/Home.vue'

import Login from '../views/Backend/Login.vue'

import AdminHome from '../views/Backend/Home.vue'
import Profile from '../views/Backend/Profile.vue'
import User from '../views/Backend/User.vue'
import Category from '../views/Backend/Category.vue'
import Menu from '../views/Backend/Menu.vue'
import Testimonial from '../views/Backend/Testimonial.vue'
import Gallery from '../views/Backend/Gallery.vue'
import Team from '../views/Backend/Team.vue'
import Contact from '../views/Backend/Contact.vue'

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },  
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { requiresVisitor: true }
  },
  {
    path: '/admin',
    name: 'AdminHome',
    component: AdminHome,
    children: [
      { path: '/admin', component: Profile, name: 'AdminProfile', meta: { requiresAuth: true } },
      { path: '/admin/user', component: User, meta: { requiresAuth: true } },
      { path: '/admin/category', component: Category, meta: { requiresAuth: true } },
      { path: '/admin/menu', component: Menu, meta: { requiresAuth: true } },
      { path: '/admin/testimonial', component: Testimonial, meta: { requiresAuth: true } },
      { path: '/admin/gallery', component: Gallery, meta: { requiresAuth: true } },
      { path: '/admin/team', component: Team, meta: { requiresAuth: true } },
      { path: '/admin/contact', component: Contact, meta: { requiresAuth: true } },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
