<template>

  <v-dialog v-model="isFormOpened" persistent max-width="600px">

    <v-snackbar :color="snackbar.color" v-model="snackbar.show" timeout="5000">
      <span>{{snackbar.message}}</span>
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar.show = false">Close</v-btn>
      </template>
    </v-snackbar>

    <v-form ref="form" v-model="isFormValid" lazy-validation>
      <v-card>

        <!-- TOOLBAR -->
        <v-toolbar dark color="mou-highlight">
          <!-- TITLE -->
          <v-toolbar-title v-if="action == 'add'">Add New Category</v-toolbar-title>
          <v-toolbar-title v-if="action == 'edit'">Edit Category</v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- CLOSE BUTTON -->
          <v-btn :disabled="isLoadingSubmit" icon dark @click="isFormOpened = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-progress-linear :style="isLoadingSubmit ? '' : 'visibility:hidden'" indeterminate color="secondary"></v-progress-linear>

        <!-- FORM INPUT -->
        <v-card-text>
          <v-container>
            <v-row>

              <!-- PHOTO -->
              <v-col cols="12" class="d-flex justify-center">
                <v-hover v-slot:default="{ hover }">
                  <div class="d-flex cursor-pointer p-relative" @click="openImageUpload()">
                    <div style="height:100px;width:100px">
                      <img contain width="100%" height="100%"
                        :src="isImageError ? imgPath.imgError : formData.imgSrc"
                          :lazy-src="imgPath.lazyPath"
                          v-on:error="isImageError = true"
                      >
                    </div>
                    <div class="p-absolute d-flex w-100 h-100 justify-center align-center" v-if="hover">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn fab small dark color="blue-grey" class="mr-1" v-bind="attrs" v-on="on">
                              <v-icon>mdi mdi-image-search</v-icon>
                          </v-btn>
                        </template>
                        <span>Change Image</span>
                      </v-tooltip>
                    </div>
                  </div>
                </v-hover>
              </v-col>
              <v-col cols="12" class="pa-0 pt-3" style="margin-top:-70px;">
                <v-row class="ma-0">
                  <v-col cols="3" class="pa-0"></v-col>
                  <v-col cols="6" class="pa-0">
                    <v-file-input :disabled="isLoadingSubmit" class="imageInput" ref="imageInput" v-on:change="onImagePicked($event)" :rules="imageRules" accept="image/png, image/jpeg, image/jpg, image/svg+xml"></v-file-input>
                  </v-col>
                  <v-col cols="3" class="pa-0"></v-col>
                </v-row>
              </v-col>

              <!-- NAME -->
              <v-col cols="12">
                <v-text-field :disabled="isLoadingSubmit" v-model="formData.name" :rules="nameRules" label="Name*" required></v-text-field>
              </v-col>

              <!-- POSITION -->
              <v-col cols="12" class="d-flex align-center">
                <v-text-field :disabled="isLoadingSubmit" v-model="formData.position" :rules="positionRules" min=1 type="number" label="Position*" required></v-text-field>
                <v-btn :disabled="isLoadingSubmit" icon @click="formData.position > 1 ? formData.position-- : 1"><v-icon>mdi-minus-circle</v-icon></v-btn>
                <v-btn :disabled="isLoadingSubmit" icon @click="formData.position++" ><v-icon>mdi-plus-circle</v-icon></v-btn>
              </v-col>

            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="isLoadingSubmit" color="grey darken-1" text @click="isFormOpened = false;">Cancel</v-btn>
          <v-btn :disabled="isLoadingSubmit" color="mou-highlight" text @click="formSave()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>

</template>

<script>
  import config from '../../config';
  export default {

    data: () => ({
      imgPath: {
        path: config.STORAGE_URL+'/category/',
        lazyPath: config.IMG_LAZY,
        imgEmpty: config.IMG_EMPTY,
        imgError: config.IMG_ERROR
      },

      action: null,
      isImageError: false,
      isFormOpened: false,
      isFormValid: true,
      isLoadingSubmit: false,
      snackbar: { show: false, color: '', message: ''},

      formData: {
        id: null,
        imgId: null,
        imgSrc: null,
        imgObj: null,
        name: null,
        position: null,
      },

      imageRules: [ 
        v => !v || v.size < 10000000 || 'Image size should be less than 10 MB!'
      ],
      nameRules: [ 
        v => (!!v) || 'Name is required', 
        v => (v && !!v.trim()) || 'Name is required', 
        v => (v && v.trim().length > 3) || 'Name must be more than 3 characters'
      ],
      positionRules: [ 
        v => (!isNaN(parseInt(v))) || 'Position must be a number', 
        v => (!isNaN(parseInt(v)) && v > 0) || 'Position must be greater than 0', 
        v => (!isNaN(parseInt(v)) && (v%1 === 0)) || 'Position cannot be a decimal', 
      ],
    }),

    methods: {
      initVariable(){
        this.action = 'add';
        this.isImageError = false;
        this.formData.id = null;
        this.formData.imgId = null;
        this.formData.imgSrc = this.imgPath.imgEmpty;
        this.formData.imgObj = null;
        this.formData.name = null;
        this.formData.position = 1;
      },
      openForm(action, item){
        if(!action){
          return;
        }

        this.initVariable();
        if(action == 'edit' && item){
          this.action = 'edit';
          this.formData.id = item.id;
          this.formData.imgId = item.imgId;
          this.formData.imgSrc = item.imgId ? this.imgPath.path+item.imgId : this.imgPath.imgEmpty;
          this.formData.imgObj = null;
          this.formData.name = item.name;
          this.formData.position = item.position;
        }
        this.isFormOpened = true;
        this.$nextTick(() => this.$refs.form.resetValidation())
      },

      formSave(){
        this.isLoadingSubmit = true;

        if(this.$refs.form.validate()){
          const target = (this.action === 'edit') ? 'backend_category/updateCategory' :  'backend_category/addCategory';
          this.$store.dispatch(target, this.formData).then((data) => {
            this.isLoadingSubmit = false;
            if(data.errors){
              this.callSnackbar('error',data.errors);
              return;
            }
            this.$emit('formCategorySaved',data)
            this.isFormOpened = false;  
          });
          return;
        }

        this.callSnackbar('error','Please fill all the required field');
        this.isLoadingSubmit = false;
      },

      openImageUpload(){
        this.$refs.imageInput.$children[0].$el.click();
      },

      onImagePicked(event){
        if(event){
          const fileReader = new FileReader();
          fileReader.readAsDataURL(event);

          fileReader.onload = (e) => {
            this.formData.imgObj = e.target.result;
          }

          this.isImageError = false;
          this.formData.imgSrc = URL.createObjectURL(event);
        }
      },

      callSnackbar(color,message){
        this.snackbar.message = message;
        this.snackbar.color = color;
        this.snackbar.show = true;
      }

    },

  }
</script>