<template>
	<v-container fluid class="white py-10">
		<v-row>
			<v-col cols="12" class="playfair mou-pretitle fw-700 text-center">Live Instagram Feed</v-col>
		</v-row>

        <v-row>
            <v-col cols="1" lg="2"></v-col>

            <v-col cols="10" lg="8">
                <v-row class="d-flex justify-center" v-if="instagramFeeds.length > 0">
                    <v-col cols="6" sm="4" md="3" lg="2" class="pa-1" 
                        v-for="(feed,index) in instagramFeeds.slice(0, 
                            $vuetify.breakpoint.xs ? 4 : $vuetify.breakpoint.sm ? 6 : $vuetify.breakpoint.md ? 8 : 12
                        )" 
                        :key="index"
                    >
                        <v-img height="100%" width="100%" :src="feed.media_url"></v-img>
                    </v-col>
                </v-row>
            </v-col>

            <v-col cols="1" lg="2"></v-col>
        </v-row>

        <v-row>
        </v-row>

	</v-container>
</template>

<script>
    import config from '../../../config'
    import instagramApi from '../../../api/instagram-api'

    export default {

        data: () => ({
            instagramFeeds: [],
        }),

        mounted () {
            this.getInstagramFeeds();
        },

        methods: {
            getInstagramFeeds(){
                instagramApi.getInstagramFeeds().then(response => {
                    this.instagramFeeds = response.data.data;
                }).catch(error => {
                    console.log('error',error)
                })
            }
        },
    
    }
</script>