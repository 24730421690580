<template>
  <v-row justify="center">
    
  <MenuGallery ref="galleryMenuRef"></MenuGallery>

  <v-dialog v-model="isDialogMenuOpened" persistent fullscreen transition="dialog-bottom-transition">
    <v-card tile class="mou-primary h-100">

    <v-row class="h-100 ma-0 pa-4 pa-sm-10">
      <v-col cols="12" class="h-100 pa-sm-0 pa-md-10">

        <v-card class="h-100" style="border-radius: 25px;box-shadow: -10px 0px 10px 1px rgba(0,0,0,.2);">

          <v-row class="h-100 ma-0">

            <v-col cols="12" md="3" lg="3" xl="2" class="d-none d-lg-block h-100">

              <v-col cols="12" class="d-flex align-center justify-center" style="height:20%;">
                <v-card flat v-ripple class="w-100 pa-2 cursor-pointer montserrat fw-400 mou-pretitle d-flex justify-center" 
                  @click="closeDialogMenu()"
                >
                  <v-icon large color="black" class="mr-2">mdi mdi-chevron-left</v-icon>HOME
                </v-card>
              </v-col>

              <v-tabs hide-slider vertical v-model="tabs">
                <v-tab class="mou-tabs" :ripple="false" active-class="transparent"
                  v-model="tabs"
                  v-for="(category,index) in getGroupedMenu" :key="category.id"
                  @click="changeCategory(category,index)"
                >
                  <v-card flat class="w-100 pa-2 cursor-pointer montserrat fw-500 mou-subtitle d-flex align-center"
                    :class="selectedCategory == category.id ? 'mou-highlight white--text white--svg' : ''"
                    style="border-radius:25px;"
                  >
                    <div class="mx-2" style="height:25px;width:25px">
                      <img :src="imgPath.pathCategory+category.imgId" contain width="100%" height="100%">
                    </div>
                    <span style="font-size:1rem;">{{category.name}}</span>
                  </v-card>
                </v-tab>
              </v-tabs>

            </v-col>

            <v-col cols="12" md="12" lg="9" xl="9" class="pa-0 h-100">
              <v-card class="h-100 pa-5 d-flex flex-column" style="border-radius: 25px;box-shadow: -10px 0px 10px 1px rgba(0,0,0,.2);">

                <v-row>
                  <v-col cols="12" class="d-flex align-center justify-center montserrat fw-400 mou-pretitle">
                    <v-row class="w-100">
                      <v-col cols="12" class="d-flex justify-center align-center">
                        Menu
                        <v-btn fab x-small="" class="p-absolute ml-10" style="left:0px;" 
                          @click="closeDialogMenu()"
                            v-if="$vuetify.breakpoint.mdAndDown"
                        >
                          <v-icon>mdi mdi-arrow-left</v-icon>
                        </v-btn>
                      </v-col>
                      
                      <v-col cols="12" v-if="$vuetify.breakpoint.mdAndDown">

                        <v-tabs grow centered center-active show-arrows hide-slider v-model="tabs">
                          <v-tab class="mou-tabs" :ripple="false" active-class="transparent"
                            v-model="tabs"
                            v-for="(category,index) in getGroupedMenu" :key="category.id"
                            @click="changeCategory(category,index)"
                          >
                            <v-card flat class="w-100 pa-2 justify-center cursor-pointer montserrat fw-500 mou-subtitle d-flex align-center"
                              :class="selectedCategory == category.id ? 'mou-highlight white--text white--svg' : ''"
                              style="border-radius:25px;"
                            >
                              <div class="mx-2" style="height:25px;width:25px">
                                <img :src="imgPath.pathCategory+category.imgId" contain width="100%" height="100%">
                              </div>
                              <span>{{category.name}}</span>
                            </v-card>
                          </v-tab>
                        </v-tabs>

                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row class="h-100 overflow-hidden">
                  <v-col cols="12" class="overflow-y-auto overflow-x-hidden h-100">
                    <v-tabs-items class="h-100" v-model="tabs">
                      <v-tab-item class="h-100" v-for="category in getGroupedMenu" :key="category.id">
                        
                        <v-row>
                          <v-col cols="6" sm="4" md="4" lg="3" style="height:90%" class="d-flex justify-center" 
                            v-for="menu in category.menu" :key="menu.id"
                          >
                              <v-row>
                                <v-col cols="12" class="d-flex justify-center">
                                  <div class="mou-primary menu-container cursor-pointer" 
                                    :style="$vuetify.breakpoint.xs ? 'width:130px;height:130px;' : 'width:150px;height:150px;'"
                                    @click="openMenuGallery(category,menu)"
                                  >
                                    <v-avatar width="100%" height="100%">
                                      <v-img class="teamImage" :src="imgPath.pathMenu+menu.imgId" height="100%" width="100%" eager>
                                        <template v-slot:placeholder>
                                          <v-row
                                            class="fill-height ma-0"
                                            align="center"
                                            justify="center"
                                          >
                                            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                          </v-row>
                                        </template>
                                      </v-img>
                                    </v-avatar>
                                  </div>
                                </v-col>
                                <v-col cols="12" class="text-center">{{menu.name}}</v-col>
                              </v-row>
                          </v-col>
                        </v-row>

                      </v-tab-item>
                    </v-tabs-items>
                  </v-col>

                </v-row>
                
              </v-card>
            </v-col>

          </v-row>

        </v-card>

      </v-col>
    </v-row>

    </v-card>
  </v-dialog>

  </v-row>
</template>

<script>
	import config from '../../../config';
  import { mapState, mapActions, mapGetters } from 'vuex'
  import MenuGallery from './Dialog-Menu-Gallery';
  
  export default {
    components: {
      MenuGallery
		},

    data: () => ({
      tabs: null,
      isDialogMenuOpened: null,
      selectedCategory: null,
      imgPath: {
        pathMenu: config.STORAGE_URL+'/menu/',
        pathCategory: config.STORAGE_URL+'/category/',
        lazyPath: config.IMG_LAZY,
        imgEmpty: config.IMG_EMPTY,
        imgError: config.IMG_ERROR
      },
      
    }),

    computed: {
      ...mapState({
        menu: state => state.menu.menu,
        category: state => state.menu.category,
      }),
      ...mapGetters({
        getGroupedMenu: 'menu/getGroupedMenu'
      }),
		},
    
    created(){
      this.getMenu();
      this.getCategory().then((data) => {
        this.selectedCategory = this.getGroupedMenu[0]?.id || 2;
      });
    },

    methods: {
      ...mapActions({
        getMenu: 'menu/getMenu',
        getCategory: 'menu/getCategory'
      }),
      
      openDialogMenu: function() {
        this.isDialogMenuOpened = true;

        // const mainAppElement = this.$root.$children[0].$children[0].$children[0].$refs['main-application'].$el;
        // mainAppElement.classList.add('p-absolute')
        // mainAppElement.classList.add('overflow-hidden')
      },

      closeDialogMenu: function() {
        this.isDialogMenuOpened = false;
        // const mainAppElement = this.$root.$children[0].$children[0].$children[0].$refs['main-application'].$el;
        // mainAppElement.classList.remove('p-absolute')
        // mainAppElement.classList.remove('overflow-hidden')

        // const scrollOption = {
        //   duration: 0,
        //   offset: this.$vuetify.breakpoint.smAndDown ? 0 : 90
        // }
        // this.$vuetify.goTo('#page-menu', this.scrollOption)
      },

      changeCategory: function(category, index){
        this.selectedCategory = category.id;
        this.tabs = index;
      },

      openMenuGallery(category, menu){
				this.$refs.galleryMenuRef.openMenuGallery(category, menu);
			},

    },

  }
</script>