import api from "../../../api/api";

// initial state
const state = () => ({
	team: []
})

// getters
const getters = {
	searchTeam: (state) => (value) => {
		const searchValue = value.toString().toLowerCase();
		function deepSearch(item){
			return Object.keys(item).some(obj => {
				if(item[obj] && obj !== 'imgId' && obj !== 'id' && obj !== 'position'){
					return typeof item[obj] === 'object' ? deepSearch(item[obj]) : item[obj].toString().toLowerCase().includes(searchValue);
				}
			})
		}
		const searchResult = state.team.filter(item => {
			return deepSearch(item);
		})
		return searchResult;
	}
}

// actions
const actions = {
	getTeam({commit}){
		return new Promise((resolve,reject) => {
			api.getTeam().then(response => {
				commit('SET_TEAM', response.data)
				resolve({'success':response.data});
			}).catch(error => {
				const errorResponse = error && error.response && error.response.data;
				resolve({'errors':errorResponse && errorResponse.message});
			})
		})
	},

	addTeam({commit},team){
		return new Promise((resolve,reject) => {
			api.addTeam(team).then(response => {
				commit('ADD_TEAM', response.data.data)
				resolve(response.data);
			}).catch(error => {
				const errorResponse = error && error.response && error.response.data;
				resolve({'errors':errorResponse && errorResponse.message});
			})
		})
	},

	updateTeam({commit},team){
		return new Promise((resolve,reject) => {
			api.updateTeam(team.id,team).then(response => {
				commit('UPDATE_TEAM', response.data.data)
				resolve(response.data);
			}).catch(error => {
				const errorResponse = error && error.response && error.response.data;
				resolve({'errors':errorResponse && errorResponse.message});
			})
		})
	},

	deleteTeam({commit},id){
		return new Promise((resolve,reject) => {
			api.deleteTeam(id).then(response => {
				commit('DELETE_TEAM', id)
				resolve(response.data);
			}).catch(error => {
				const errorResponse = error && error.response && error.response.data;
				resolve({'errors':errorResponse && errorResponse.message});
			})
		})
	}
}

// mutations
const mutations = {
	SET_TEAM(state, team){
		state.team = team;
	},

	ADD_TEAM(state, team){
		state.team.unshift(team);
	},

	UPDATE_TEAM(state, team){
		const updateIndex = state.team.findIndex((data) => data	.id === team.id);
		state.team.splice(updateIndex,1,team);
	},

	DELETE_TEAM(state, id){
		const deleteIndex = state.team.findIndex((data) => data.id === id);
		state.team.splice(deleteIndex, 1);
	}
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}