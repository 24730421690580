import api from "../../../api/api";

// initial state
const state = () => ({
	category: []
})

// getters	
const getters = {
	searchCategory: (state) => (value) => {
		const searchValue = value.toString().toLowerCase();
		function deepSearch(item){
			return Object.keys(item).some(obj => {
				if(item[obj] && obj !== 'imgId' && obj !== 'id' && obj !== 'position'){
					return typeof item[obj] === 'object' ? deepSearch(item[obj]) : item[obj].toString().toLowerCase().includes(searchValue);
				}
			})
		}
		const searchResult = state.category.filter(item => {
			return deepSearch(item);
		})
		return searchResult;
	}
}

// actions
const actions = {
	getCategory({commit}){
		return new Promise((resolve,reject) => {
			api.getCategory().then(response => {
				commit('SET_CATEGORY', response.data)
				resolve({'success':response.data});
			}).catch(error => {
				const errorResponse = error && error.response && error.response.data;
				resolve({'errors':errorResponse && errorResponse.message});
			})
		})
	},

	addCategory({commit},category){
		return new Promise((resolve,reject) => {
			api.addCategory(category).then(response => {
				commit('ADD_CATEGORY', response.data.data)
				resolve(response.data);
			}).catch(error => {
				const errorResponse = error && error.response && error.response.data;
				resolve({'errors':errorResponse && errorResponse.message});
			})
		})
	},

	updateCategory({commit},category){
		return new Promise((resolve,reject) => {
			api.updateCategory(category.id,category).then(response => {
				commit('UPDATE_CATEGORY', response.data.data)
				resolve(response.data);
			}).catch(error => {
				const errorResponse = error && error.response && error.response.data;
				resolve({'errors':errorResponse && errorResponse.message});
			})
		})
	},

	deleteCategory({commit},id){
		return new Promise((resolve,reject) => {
			api.deleteCategory(id).then(response => {
				commit('DELETE_CATEGORY', id)
				resolve(response.data);
			}).catch(error => {
				const errorResponse = error && error.response && error.response.data;
				resolve({'errors':errorResponse && errorResponse.message});
			})
		})
	}  
}

// mutations
const mutations = {
	SET_CATEGORY(state, category){
		state.category = category;
	},

	ADD_CATEGORY(state, category){
		state.category.unshift(category);
	},

	UPDATE_CATEGORY(state, category){
		const updateIndex = state.category.findIndex((data) => data	.id === category.id);
		state.category.splice(updateIndex,1,category);
	},

	DELETE_CATEGORY(state, id){
		const deleteIndex = state.category.findIndex((data) => data.id === id);
		state.category.splice(deleteIndex, 1);
	}
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}