<template>
	<v-container fluid dark class="py-10 white overflow-hidden" ref="menu">

		<DialogMenu ref="dialogMenuRef"></DialogMenu>
		<MenuGallery ref="galleryMenuRef"></MenuGallery>

		<v-row>
			<v-col cols="12" class="playfair fw-700 text-center mou-title">Our Menus</v-col>
			<v-col cols="12" class="montserrat fw-400 text-center mou-caption-1">Special fresh menus, made for you</v-col>
		</v-row>

		<div class="pa-5" style="margin-left:-12px;margin-right:-12px;" v-if="getMenuSliderImage && getMenuSliderImage.length > 0">
			<VueSlickCarousel v-bind="carouselSettings">

				<v-row v-for="category in getMenuSliderImage" :key="category.id" class="pa-1 ma-0 no-outline">
					<v-col cols="12" class="d-flex justify-center">
						<v-row class="d-flex justify-center">

							<v-col :cols="$vuetify.breakpoint.xs ? '12' :
														$vuetify.breakpoint.sm ? '6' :
														$vuetify.breakpoint.md ? '4' :
														$vuetify.breakpoint.lg ? '3' : '2'
														" 
									v-for="menu in category.menu.slice(0,(category.menu.length < (
										$vuetify.breakpoint.xs ? '1' :
										$vuetify.breakpoint.sm ? '2' :
										$vuetify.breakpoint.md ? '3' :
										$vuetify.breakpoint.lg ? '4' : '5'
									)) ? category.menu.length : (
										$vuetify.breakpoint.xs ? '1' :
										$vuetify.breakpoint.sm ? '2' :
										$vuetify.breakpoint.md ? '3' :
										$vuetify.breakpoint.lg ? '4' : '5'
									))" 
									:key="menu.id"
							>
								<v-row>

									<v-col cols="12" class="d-flex justify-center">
										<div class="mou-primary menu-container cursor-pointer" @click="openMenuGallery(category,menu)">
											<v-avatar width="100%" height="100%">
												<v-img class="teamImage" :src="imgPath.path+menu.imgId" height="100%" width="100%" eager>
													<template v-slot:placeholder>
														<v-row
															class="fill-height ma-0"
															align="center"
															justify="center"
														>
															<v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
														</v-row>
													</template>
												</v-img>
											</v-avatar>
										</div>
									</v-col>
									<v-col cols="12" class="text-center montserrat fw-500 mou-subtitle">{{category.name}}</v-col>

								</v-row>
							</v-col>
								
						</v-row>
					</v-col>
				</v-row>

				<template #prevArrow>
								<v-btn fab small class="p-absolute ml-5 mou-highlight white--text"><v-icon>mdi mdi-chevron-left</v-icon></v-btn>
				</template>

				<template #nextArrow>
								<v-btn fab small class="p-absolute mr-5 mou-highlight white--text"><v-icon>mdi mdi-chevron-right</v-icon></v-btn>
				</template>

			</VueSlickCarousel>
		</div>     

		<v-row class="mt-5">
			<v-col cols="12" class="montserrat fw-700 text-center">
				<v-btn outlined rounded x-large color="warning" style="border:solid 3px;" @click="openDialogMenu()">
					<span class="mou-normal--text px-5">MORE</span>
				</v-btn>
		</v-col>
		</v-row>

	</v-container>
</template>

<script>
	import config from '../../../config';
	import { mapState, mapActions, mapGetters } from 'vuex'
	import VueSlickCarousel from 'vue-slick-carousel'
	import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
	import DialogMenu from './Dialog-Menu';
  import MenuGallery from './Dialog-Menu-Gallery';

  export default {
    name: 'MyComponent',
    components: { VueSlickCarousel, DialogMenu, MenuGallery },

    data: () => ({
			maxMenuView: 0,
			colMenuView: 12,
			carouselSettings: {
				"arrows": true,
				"dots": false,
				"infinite": true,
				"speed": 500,
				"slidesToShow": 1,
				"slidesToScroll": 1,
				"touchThreshold": 9,
				"initialSlide": 0
			},
			imgPath: {
        path: config.STORAGE_URL+'/menu/',
        lazyPath: config.IMG_LAZY,
        imgEmpty: config.IMG_EMPTY,
        imgError: config.IMG_ERROR
      },
		}),
		
		computed: {
      ...mapState({
        menu: state => state.menu.menu,
        category: state => state.menu.category,
			}),
			...mapGetters({
        getMenuSliderImage: 'menu/getMenuSliderImage'
			})
		},

    mounted () {
			this.getMenu();
      this.getCategory();
    },
		
    methods:{
			...mapActions({
        getMenu: 'menu/getMenu',
        getCategory: 'menu/getCategory'
			}),
			
			openDialogMenu(){
				this.$refs.dialogMenuRef.openDialogMenu();
			},

			openMenuGallery(category, menu){
				this.$refs.galleryMenuRef.openMenuGallery(category, menu);
			},
    }

  }
</script>