<template>
  <v-row justify="center">
    <v-dialog v-model="isGalleryOpened" fullscreen transition="dialog-bottom-transition">
      <v-card tile :class="$vuetify.breakpoint.xs ? 'mou-dark h-100 w-100' : 'mou-normal h-100 w-100'">

        <v-row class="h-100 ma-0 d-flex align-center">

          <div class="p-absolute" style="top:10px;right:10px;z-index:9;">
            <v-btn icon dark x-large @click="isGalleryOpened = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          

					<v-col cols="12" class="h-100 pa-0">
						<v-carousel v-model="galleryIndex" hide-delimiters height="100%">
							<v-carousel-item v-for="gallery in galleryList" :key="gallery.id">
                <v-row class="ma-0 h-100 d-flex justify-center align-center">
                  <v-col class="h-100 d-flex align-center justify-center" cols="10">
                    <v-img 
                      :src="imgPath.path+gallery.imgId" class="d-flex justify-center align-center" 
                      width="auto" 
                      :height="$vuetify.breakpoint.xs ? '300px' : $vuetify.breakpoint.sm ? '400px' : '100%'"
                      contain
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                    <div
                        class="p-absolute d-flex align-center white--text mou-caption-1 w-100 px-10"
                        style="min-height: 100px;bottom:0;background:rgba(10, 10, 10, .5) !important"
                      >
                        <span class="py-5" style="white-space:break-spaces;">{{gallery.description}}</span>
                      </div>
                  </v-col>
                </v-row>
                
              </v-carousel-item>
						</v-carousel>
					</v-col>

        </v-row>

      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  import config from '../../../config';
  import { mapState } from 'vuex'

  export default {

    data: () => ({
			isGalleryOpened: false,
      galleryIndex: 0,
      imgPath: {
        path: config.STORAGE_URL+'/gallery/',
        lazyPath: config.IMG_LAZY,
        imgEmpty: config.IMG_EMPTY,
        imgError: config.IMG_ERROR
      },
    }),

    computed: mapState({
			galleryList: state => state.gallery.gallery,
		}),

    methods: {
      openGallery: function (index) {
				this.galleryIndex = index;
        this.isGalleryOpened = true;
      }
    },

  }
</script>