import api from "../../../api/frontend-api";

// initial state
const state = () => ({
	menu: [],
	category: []
})
  
  // getters
  const getters = {
		getMenuSliderImage: (state)  => {

			const category = JSON.parse(JSON.stringify(state.category));
			const menu = JSON.parse(JSON.stringify(state.menu));

			category.forEach(categoryItem => {
				const menuCategory = menu.filter(menuItem => menuItem.category.id === categoryItem.id);
				if(menuCategory.length > 5){
					menuCategory.splice(5);
				}
				categoryItem.menu = menuCategory;

			})

			const categoryMenu = category.filter((ctg) => ctg.menu.length > 0);
			return categoryMenu;
		},

		getGroupedMenu: (state)  => {
			const category = JSON.parse(JSON.stringify(state.category));
			const menu = JSON.parse(JSON.stringify(state.menu));
			category.forEach(categoryItem => {
				const menuCategory = menu.filter(menuItem => menuItem.category.id === categoryItem.id);
				categoryItem.menu = menuCategory;
			})
			const categoryMenu = category.filter((ctg) => ctg.menu.length > 0);
			return categoryMenu;
		},
  }
  
  // actions
  const actions = {
		getMenu({commit}){
			return new Promise((resolve,reject) => {
				api.getMenu().then(response => {
					commit('SET_MENU', response.data)
					resolve({'success':response.data});
				}).catch(error => {
					const errorResponse = error && error.response && error.response.data;
					resolve({'errors':errorResponse && errorResponse.message});
				})
			})
		},
		getCategory({commit}){
			return new Promise((resolve,reject) => {
				api.getCategory().then(response => {
					commit('SET_CATEGORY', response.data)
					resolve({'success':response.data});
				}).catch(error => {
					const errorResponse = error && error.response && error.response.data;
					resolve({'errors':errorResponse && errorResponse.message});
				})
			})
		} 
	}
  
  // mutations
  const mutations = {
		SET_MENU(state, menu){
			state.menu = menu;
		},
		SET_CATEGORY(state, category){
			state.category = category;
		}
	}
  
  export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
  }