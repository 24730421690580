<template>
  <v-container fill-height fluid class="overflow-auto px-7 pb-7">

    <FormGallery ref="formGallery" @formGallerySaved="createUpdateData"></FormGallery>
    <DialogDeleteConfirmation ref="dialogDeleteConfirmation" @dialogDeleteConfirmed="deleteData"></DialogDeleteConfirmation>

    <v-snackbar :color="snackbar.color" v-model="snackbar.show" timeout="5000">
      <span>{{snackbar.message}}</span>
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar.show = false">Close</v-btn>
      </template>
    </v-snackbar>
    
    <v-row class="justify-center align-self-start">
      <v-col cols="12" sm="10" md="10">
        <v-layout column>
          
          <!-- DETAIL -->
          <v-flex>

            <!-- TITLE -->
            <v-row>
              <v-col cols="6">
                <span class="title d-flex"><v-icon class="mr-2">mdi-image-multiple-outline</v-icon>Gallery Management</span>
              </v-col>
              <v-col cols="6" class="text-right">
                <v-btn :disabled="isLoadingData" small color="mou-highlight" dark @click="openFormGallery('add')">
                  <v-icon left small>mdi-image-plus</v-icon>Upload
                </v-btn>
              </v-col>
            </v-row>

            <v-divider></v-divider>
            <v-progress-linear :style="isLoadingData ? '' : 'visibility:hidden'" indeterminate color="warning"></v-progress-linear>

            <v-row>
              <!-- SEARCH BOX -->
              <v-col cols="12">
                <v-text-field :disabled="isLoadingData" dense solo v-model="searchValue" append-icon="mdi-magnify" label="Search" single-line hide-details>
                </v-text-field>
              </v-col>
            </v-row>

          </v-flex>
          
          <!-- TABLE -->
          <v-flex xs12 class="mt-2" style="height:0px;">
            <v-row>

              <v-col cols="12" class="mt-5 mou-grey--text mou-caption-1 text-center" v-if="galleryList(this.searchValue).length === 0">
                No Data Available
              </v-col>

              <v-slide-y-transition hide-on-leave group class="row ma-0 w-100">

                <v-col cols="12" sm="6" md="4" lg="3" v-for="gallery in galleryList(this.searchValue)" :key="gallery.id">
                  

                  <v-card class="mx-auto p-relative" :key="gallery.id">
                    <v-img class="white--text align-end" height="200px" 
                      :src="gallery.imgError ? gallery.imgError : gallery.imgId ? imgPath.path+gallery.imgId+random() : imgPath.imgEmpty"
                      :lazy-src="imgPath.lazyPath"
                      v-on:error="gallery.imgError = imgPath.imgError"
                    >
                      <template v-slot:placeholder>
                        <v-row class="fill-height ma-0" align="center" justify="center">
                          <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>

                    <div class="p-absolute" style="top:10px;right:10px;">
                      <v-chip small color="mou-secondary" text-color="white">
                        Position <v-avatar right class="grey lighten-3 black--text">{{gallery.position}}</v-avatar>
                      </v-chip>
                    </div>

                    <v-card-text class="text--primary" style="border-top:1px solid #eee;">
                      <div style="white-space: break-spaces;">{{gallery.description}}</div>
                    </v-card-text>

                    <v-card-actions class="d-flex justify-space-between">
                      <v-btn :disabled="isLoadingData" color="error" text @click="openDialogDeleteConfirmation(gallery.id,gallery.description)">Delete</v-btn>
                      <v-btn :disabled="isLoadingData" color="orange" text @click="openFormGallery('edit',gallery)">Edit</v-btn>
                    </v-card-actions>
                  </v-card>

                </v-col>
              </v-slide-y-transition>

            </v-row>
          </v-flex>

        </v-layout>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
  import config from '../../config';
  import { mapState, mapGetters, mapActions } from 'vuex';
  import FormGallery from '../../components/Backend/FormGallery';
  import DialogDeleteConfirmation from '../../components/Backend/DialogDeleteConfirmation';

  export default {
    components: {
      FormGallery,
      DialogDeleteConfirmation
    },

    data: () => ({
      isLoadingData: false,
      snackbar: { show: false, color: '', message: ''},

      imgPath: {
        path: config.STORAGE_URL+'/gallery/',
        lazyPath: config.IMG_LAZY,
        imgEmpty: config.IMG_EMPTY,
        imgError: config.IMG_ERROR
      },
      searchValue: '',
    }),

    computed: {
      ...mapState({
        gallery: state => state.backend_gallery.gallery,
      }),
      ...mapGetters({
        galleryList: 'backend_gallery/searchGallery',
      })
    },

    mounted(){
      this.isLoadingData = true;
      this.getGallery().then((response) => {
        if(response.errors){
          this.callSnackbar('error',response.errors);
          return;
        }
        this.isLoadingData = false;
      });
    },

    methods: {
      ...mapActions({
        getGallery: 'backend_gallery/getGallery'
      }),

      random(){
        const now = Date.now();
        return '?'+now;
      },

      openFormGallery: function (action, item){
        this.$refs.formGallery.openForm(action, item);
      },

      openDialogDeleteConfirmation: function (id, name){
        this.$refs.dialogDeleteConfirmation.openDialog(id, name);
      },

      createUpdateData(data){
        if(data.errors){
          this.callSnackbar('error',data.errors)
          return;
        }
        this.callSnackbar('success',data.success)
      },

      deleteData(id){
        this.isLoadingData = true;
        this.$store.dispatch('backend_gallery/deleteGallery',id).then((data) => {
          this.isLoadingData = false;
          if(data.errors){
            this.callSnackbar('error',data.errors);
            return;
          }
          this.callSnackbar('success',data.success);
        });
      },

      callSnackbar(color,message){
        this.snackbar.message = message;
        this.snackbar.color = color;
        this.snackbar.show = true;
      }
    }

  }
</script>