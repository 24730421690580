import api from "../../../api/frontend-api";

// initial state
const state = () => ({
	gallery: [],
})

// getters
const getters = {}

// actions
const actions = {
	getGallery({commit}){
		return new Promise((resolve,reject) => {
			api.getGallery().then(response => {
				commit('SET_GALLERY', response.data)
				resolve({'success':response.data});
			}).catch(error => {
				const errorResponse = error && error.response && error.response.data;
				resolve({'errors':errorResponse && errorResponse.message});
			})
		})
	},
}

// mutations
const mutations = {
	SET_GALLERY(state, gallery){
		state.gallery = gallery;
	},
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}