var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{ref:"menu",staticClass:"py-10 white overflow-hidden",attrs:{"fluid":"","dark":""}},[_c('DialogMenu',{ref:"dialogMenuRef"}),_c('MenuGallery',{ref:"galleryMenuRef"}),_c('v-row',[_c('v-col',{staticClass:"playfair fw-700 text-center mou-title",attrs:{"cols":"12"}},[_vm._v("Our Menus")]),_c('v-col',{staticClass:"montserrat fw-400 text-center mou-caption-1",attrs:{"cols":"12"}},[_vm._v("Special fresh menus, made for you")])],1),(_vm.getMenuSliderImage && _vm.getMenuSliderImage.length > 0)?_c('div',{staticClass:"pa-5",staticStyle:{"margin-left":"-12px","margin-right":"-12px"}},[_c('VueSlickCarousel',_vm._b({scopedSlots:_vm._u([{key:"prevArrow",fn:function(){return [_c('v-btn',{staticClass:"p-absolute ml-5 mou-highlight white--text",attrs:{"fab":"","small":""}},[_c('v-icon',[_vm._v("mdi mdi-chevron-left")])],1)]},proxy:true},{key:"nextArrow",fn:function(){return [_c('v-btn',{staticClass:"p-absolute mr-5 mou-highlight white--text",attrs:{"fab":"","small":""}},[_c('v-icon',[_vm._v("mdi mdi-chevron-right")])],1)]},proxy:true}],null,false,4092038746)},'VueSlickCarousel',_vm.carouselSettings,false),_vm._l((_vm.getMenuSliderImage),function(category){return _c('v-row',{key:category.id,staticClass:"pa-1 ma-0 no-outline"},[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c('v-row',{staticClass:"d-flex justify-center"},_vm._l((category.menu.slice(0,(category.menu.length < (
									_vm.$vuetify.breakpoint.xs ? '1' :
									_vm.$vuetify.breakpoint.sm ? '2' :
									_vm.$vuetify.breakpoint.md ? '3' :
									_vm.$vuetify.breakpoint.lg ? '4' : '5'
								)) ? category.menu.length : (
									_vm.$vuetify.breakpoint.xs ? '1' :
									_vm.$vuetify.breakpoint.sm ? '2' :
									_vm.$vuetify.breakpoint.md ? '3' :
									_vm.$vuetify.breakpoint.lg ? '4' : '5'
								))),function(menu){return _c('v-col',{key:menu.id,attrs:{"cols":_vm.$vuetify.breakpoint.xs ? '12' :
													_vm.$vuetify.breakpoint.sm ? '6' :
													_vm.$vuetify.breakpoint.md ? '4' :
													_vm.$vuetify.breakpoint.lg ? '3' : '2'}},[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c('div',{staticClass:"mou-primary menu-container cursor-pointer",on:{"click":function($event){return _vm.openMenuGallery(category,menu)}}},[_c('v-avatar',{attrs:{"width":"100%","height":"100%"}},[_c('v-img',{staticClass:"teamImage",attrs:{"src":_vm.imgPath.path+menu.imgId,"height":"100%","width":"100%","eager":""},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)})],1)],1)]),_c('v-col',{staticClass:"text-center montserrat fw-500 mou-subtitle",attrs:{"cols":"12"}},[_vm._v(_vm._s(category.name))])],1)],1)}),1)],1)],1)}),1)],1):_vm._e(),_c('v-row',{staticClass:"mt-5"},[_c('v-col',{staticClass:"montserrat fw-700 text-center",attrs:{"cols":"12"}},[_c('v-btn',{staticStyle:{"border":"solid 3px"},attrs:{"outlined":"","rounded":"","x-large":"","color":"warning"},on:{"click":function($event){return _vm.openDialogMenu()}}},[_c('span',{staticClass:"mou-normal--text px-5"},[_vm._v("MORE")])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }