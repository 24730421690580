import api from "../../../api/api";

// initial state
const state = () => ({
	gallery: [],
})

// getters
const getters = {
  searchGallery: (state) => (value) => {
		const searchValue = value.toString().toLowerCase();
		function deepSearch(item){
			return Object.keys(item).some(obj => {
				if(item[obj] && obj !== 'imgId' && obj !== 'id'){
					return typeof item[obj] === 'object' ? deepSearch(item[obj]) : item[obj].toString().toLowerCase().includes(searchValue);
				}
			})
		}
		const searchResult = state.gallery.filter(item => {
			return deepSearch(item);
		})
		return searchResult;
	}
}

// actions
const actions = {
	getGallery({commit}){
		return new Promise((resolve,reject) => {
			api.getGallery().then(response => {
				commit('SET_GALLERY', response.data)
				resolve({'success':response.data});
			}).catch(error => {
				const errorResponse = error && error.response && error.response.data;
				resolve({'errors':errorResponse && errorResponse.message});
			})
		})
	},

	addGallery({commit},gallery){
		return new Promise((resolve,reject) => {
			api.addGallery(gallery).then(response => {
				commit('ADD_GALLERY', response.data.data)
				resolve(response.data);
			}).catch(error => {
				const errorResponse = error && error.response && error.response.data;
				resolve({'errors':errorResponse && errorResponse.message});
			})
		})
	},

	updateGallery({commit},gallery){
		return new Promise((resolve,reject) => {
			api.updateGallery(gallery.id,gallery).then(response => {
				commit('UPDATE_GALLERY', response.data.data)
				resolve(response.data);
			}).catch(error => {
				const errorResponse = error && error.response && error.response.data;
				resolve({'errors':errorResponse && errorResponse.message});
			})
		})
	},

	deleteGallery({commit},id){
		return new Promise((resolve,reject) => {
			api.deleteGallery(id).then(response => {
				commit('DELETE_GALLERY', id)
				resolve(response.data);
			}).catch(error => {
				const errorResponse = error && error.response && error.response.data;
				resolve({'errors':errorResponse && errorResponse.message});
			})
		})
	}
}

// mutations
const mutations = {
	SET_GALLERY(state, gallery){
		state.gallery = gallery;
	},

	ADD_GALLERY(state, gallery){
		state.gallery.unshift(gallery);
	},

	UPDATE_GALLERY(state, gallery){
		const updateIndex = state.gallery.findIndex((data) => data	.id === gallery.id);
		state.gallery.splice(updateIndex,1,gallery);
	},

	DELETE_GALLERY(state, id){
		const deleteIndex = state.gallery.findIndex((data) => data.id === id);
		state.gallery.splice(deleteIndex, 1);
	}
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}