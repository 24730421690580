<template>
  <v-container fill-height fluid class="overflow-auto px-7 pb-7">
    
    <v-snackbar :color="snackbar.color" v-model="snackbar.show" timeout="5000">
      <span>{{snackbar.message}}</span>
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar.show = false">Close</v-btn>
      </template>
    </v-snackbar>

    <v-form ref="form" class="w-100 h-100" v-model="isFormValid" lazy-validation>
      <v-row class="justify-center align-self-start">
        <v-col cols="12" sm="10" md="10">
          <v-layout column>
            
            <!-- DETAIL -->
            <v-flex>

              <!-- TITLE -->
              <v-row>
                <v-col cols="12" sm="6">
                  <span class="title d-flex"><v-icon class="mr-2">mdi-office-building-marker-outline</v-icon>Contact Management</span>
                </v-col>
                <v-col cols="12" sm="6" class="text-center text-sm-right">
                  <v-btn small color="mou-highlight" dark @click="formEdit()" v-if="!isFormEdit" :disabled="isLoadingData">
                    <v-icon left small>mdi-pencil</v-icon>Edit
                  </v-btn>
                  <v-btn small color="grey" class="mr-2" dark @click="formCancel()" v-if="isFormEdit">
                    <v-icon left small>mdi-close</v-icon>Cancel
                  </v-btn>
                  <v-btn small color="success" dark @click="formSave()" v-if="isFormEdit">
                    <v-icon left small>mdi-check</v-icon>Save
                  </v-btn>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-progress-linear :style="isLoadingData ? '' : 'visibility:hidden'" indeterminate color="warning"></v-progress-linear>

            </v-flex>
            
            <!-- TABLE -->
            <v-flex xs12 class="mt-2" style="height:0px;">
                <v-row>
                  <v-col cols="12">
                      
                    <v-row>
                      <v-col cols="12" class="mou-subtitle fw-500 pb-0">
                        Address
                      </v-col>
                      <v-col cols="12">
                        <v-textarea solo auto-grow hide-details="auto" :readonly="!isFormEdit" 
                          :rules="addressRules" v-model="formData.address" placeholder="Address"
                        ></v-textarea>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" class="mou-subtitle fw-500 pb-0">
                        Opening Hours
                      </v-col>
                      <v-col cols="12">
                        <v-text-field solo auto-grow hide-details="auto" 
                          :readonly="!isFormEdit" v-model="formData.openHour" placeholder="Opening Hours"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row class="mt-10">
                      <v-col cols="12" class="pb-0 mou-subtitle fw-500 pb-0">Contact Number</v-col>
                    </v-row>
                    <v-divider class="mt-3"></v-divider>

                    <v-row>
                      <v-col cols="12" v-if="formData.contact_number.length === 0">
                        <v-card outlined class="pa-5">No contact number</v-card>
                      </v-col>
                      <v-col cols="12" class="pb-0" v-for="(number, index) in formData.contact_number" :key="number.id">
                        <v-card outlined class="pa-5">
                          <v-row>
                            <v-col cols="12" class="py-0">
                              <v-row>
                                <v-col cols="12" md="3">
                                  <v-text-field v-model=" number.number" :readonly="!isFormEdit" :rules="numberRules" label="Number*" required></v-text-field>
                                </v-col>
                                <v-col cols="12" md="3">
                                  <v-text-field v-model="number.detail" :readonly="!isFormEdit" label="Detail"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="3">
                                  <v-select menu-props="auto" label="Icon*" required :prepend-icon="number.icon" :rules="iconRules"
                                    v-model="number.icon" :readonly="!isFormEdit" :items="iconNumberSelector" item-text="name" item-value="icon"
                                  ></v-select>
                                </v-col>
                                <v-col cols="12" md="3" class="d-flex align-center">
                                  <v-text-field v-model="number.position" :readonly="!isFormEdit" :rules="positionRules" min=1 type="number" label="Position*" required></v-text-field>
                                  <v-btn icon v-if="isFormEdit" @click="number.position > 1 ? number.position-- : 1"><v-icon>mdi-minus-circle</v-icon></v-btn>
                                  <v-btn icon v-if="  isFormEdit" @click="number.position++" ><v-icon>mdi-plus-circle</v-icon></v-btn>
                                </v-col>
                                <v-col cols="12" class="text-right py-0" v-if="isFormEdit">
                                  <v-btn small outlined color="error" @click="removeNumber(index)">Remove</v-btn>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-col>

                      <v-col cols="12" v-if="isFormEdit">
                        <v-btn small color="mou-highlight" dark @click="addNumber()"><v-icon>mdi mdi-plus</v-icon>Add New</v-btn>
                      </v-col>
                    </v-row>



                    <v-row class="mt-10">
                      <v-col cols="12" class="pb-0 mou-subtitle fw-500 pb-0">Social Media</v-col>
                    </v-row>
                    <v-divider class="mt-3"></v-divider>

                    <v-row>
                      <v-col cols="12" v-if="formData.social_media.length === 0">
                        <v-card outlined class="pa-5">No social media</v-card>
                      </v-col>
                      <v-col cols="12" class="pb-0" v-for="(social, index) in formData.social_media" :key="social.id">
                        <v-card outlined class="pa-5">
                          <v-row>
                            <v-col cols="12" class="py-0">
                              <v-row>
                                <v-col cols="12" md="3">
                                  <v-text-field v-model="social.account" :readonly="!isFormEdit" :rules="accountRules" label="Account*" required></v-text-field>
                                </v-col>
                                <v-col cols="12" md="3">
                                  <v-text-field v-model="social.url" :readonly="!isFormEdit" :rules="urlRules" label="URL*" required></v-text-field>
                                </v-col>
                                <v-col cols="12" md="3">
                                  <v-select menu-props="auto" label="Icon*" required :prepend-icon="social.icon" :rules="iconRules"
                                    v-model="social.icon" :readonly="!isFormEdit" :items="iconSocialSelector" item-text="name" item-value="icon"
                                  ></v-select>
                                </v-col>
                                <v-col cols="12" md="3" class="d-flex align-center">
                                  <v-text-field v-model="social.position" :readonly="!isFormEdit" :rules="positionRules" min=1 type="number" label="Position*" required></v-text-field>
                                  <v-btn icon v-if="isFormEdit" @click="social.position > 1 ? social  .position-- : 1"><v-icon>mdi-minus-circle</v-icon></v-btn>
                                  <v-btn icon v-if="isFormEdit" @click="social.position++" ><v-icon>mdi-plus-circle</v-icon></v-btn>
                                </v-col>
                                <v-col cols="12" class="text-right py-0" v-if="isFormEdit">
                                  <v-btn small outlined color="error" @click="removeSocial(index)">Remove</v-btn>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-col>

                      <v-col cols="12" v-if="isFormEdit">
                        <v-btn :disabled="!isFormEdit" small color="mou-highlight" dark @click="addSocial()"><v-icon>mdi mdi-plus</v-icon>Add New</v-btn>
                      </v-col>
                    </v-row>

                  </v-col>
                </v-row>
            </v-flex>

          </v-layout>
        </v-col>
      </v-row>
    </v-form>

  </v-container>
</template>

<script>
  import { mapState, mapActions } from 'vuex'

  export default {
    data: () => ({
      isLoadingData: false,
      isFormValid: true,
      isFormEdit: false,
      snackbar: { show: false, color: '', message: ''},

      formData: {
        id: null,
        address: null,
        openHour: null,
        contact_number: [],
        social_media: []
      },

      iconNumberSelector:[
        {name:'Phone', icon:'mdi mdi-phone'},
        {name:'Cellphone', icon:'mdi mdi-cellphone-iphone'},
        {name:'SMS', icon:'mdi mdi-message-processing'},
        {name:'Whatsapp', icon:'mdi mdi-whatsapp'},
        {name:'Telegram', icon:'mdi mdi-telegram'},
        {name:'Line', icon:'fab fa-line'},
        {name:'Fb Messenger', icon:'mdi mdi-facebook-messenger'},
        {name:'WeChat', icon:'mdi mdi-wechat'},
        {name:'Skype', icon:'mdi mdi-skype'},
      ],

      iconSocialSelector:[
        {name:'Instagram', icon:'mdi mdi-instagram'},
        {name:'Facebook', icon:'mdi mdi-facebook'},
        {name:'Twitter', icon:'mdi mdi-twitter'},
        {name:'Youtube', icon:'mdi mdi-youtube'},
        {name:'LinkedIn', icon:'mdi mdi-linkedin'},
        {name:'Pinterest', icon:'mdi mdi-pinterest'},
        {name:'Snapchat', icon:'mdi mdi-snapchat'},
      ],

      addressRules: [ 
        v => (!!v) || 'Address is required', 
        v => (v && !!v.trim()) || 'Address is required', 
        v => (v && v.trim().length > 4) || 'Address must be more than 4 characters'
      ],
      numberRules: [ 
        v => (!!v) || 'Number is required', 
        v => (v && !!v.trim()) || 'Number is required', 
        v => (v && v.trim().length > 4) || 'Number must be more than 4 characters'
      ],
      iconRules: [ 
        v => !!v|| 'Icon is required', 
      ],
      positionRules: [ 
        v => (!isNaN(parseInt(v))) || 'Position must be a number', 
        v => (!isNaN(parseInt(v)) && v > 0) || 'Position must be greater than 0', 
        v => (!isNaN(parseInt(v)) && (v%1 === 0)) || 'Position cannot be a decimal', 
      ],
      accountRules: [ 
        v => !!v|| 'Account is required', 
        v => (v && !!v.trim()) || 'Account is required', 
        v => (v && v.trim().length > 4) || 'Account must be more than 4 characters'
      ],
      urlRules: [ 
        v => !!v|| 'URL is required', 
        v => (v && !!v.trim()) || 'URL is required', 
        v => (v && v.trim().length > 4) || 'URL must be more than 4 characters'
      ],
    }),

    computed: {
      ...mapState({
        contact: state => state.backend_contact.contact,
      }),
    },

    mounted(){
      this.isLoadingData = true;
      this.getContact().then((response) => {
        if(response.errors){
          this.callSnackbar('error',response.errors);
          return;
        }
        this.initVariable();
        this.isLoadingData = false;
      });
    },

    methods: {
      ...mapActions({
        getContact: 'backend_contact/getContact'
      }),

      initVariable(){
        this.formData.id = null;
        this.formData.address = null;
        this.formData.openHour = null;
        this.formData.contact_number = [];
        this.formData.social_media = [];

        this.formData.id = this.contact.id || null;
        this.formData.address = this.contact.address || null;
        this.formData.openHour = this.contact.openHour || null;
        this.formData.contact_number = this.contact && this.contact.contact_number && JSON.parse(JSON.stringify(this.contact.contact_number)) || [];
        this.formData.social_media = this.contact && this.contact.social_media && JSON.parse(JSON.stringify(this.contact.social_media)) || []; 
      },

      formEdit(){ 
        this.isFormEdit = true;
      },

      formCancel(){
        this.initVariable();
        this.isFormEdit = false;
      },

      formSave(){
        const incoming = JSON.stringify(this.formData);
        const current = JSON.stringify(this.contact);
        if(incoming === current){
          this.isFormEdit = false;
          return;
        }

        this.isLoadingData = true;
        if(this.$refs.form.validate()){
          this.$store.dispatch('backend_contact/updateContact', this.formData).then((data) => {
            this.isLoadingData = false;
            this.isFormEdit = false;
            if(data.errors){
              this.callSnackbar('error',data.errors);
              return;
            }
            this.callSnackbar('success',data.success);
          });
          return;
        }

        this.callSnackbar('error','Please fill all the required field');
        this.isLoadingData = false;
      },

      addNumber(){
        const contact_number = {
          id: null,
          position: 1,
          number: null,
          detail: null,
          icon: null
        }
        this.formData.contact_number.push(contact_number)
      },

      removeNumber(index){
        this.formData.contact_number.splice(index, 1);
      },

      addSocial(){
        const social_media = {
          id: null,
          position: 1,
          account: null,
          url: null,
          icon: null
        }
        this.formData.social_media.push(social_media)
      },

      removeSocial(index){
        this.formData.social_media.splice(index, 1);
      },

      callSnackbar(color,message){
        this.snackbar.message = message;
        this.snackbar.color = color;
        this.snackbar.show = true;
      }

    }

  }
</script>