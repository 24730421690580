<template>
	<v-container fluid class="mou-primary py-10" ref="lab">

		<Gallery ref="galleryRef"></Gallery>

		<v-row>
			<v-col cols="12" class="playfair mou-title fw-700 text-center">Look at Our Gelato Lab</v-col>
		</v-row>

		<v-row>
				<v-col cols="1"></v-col>
				<v-col cols="10" lg="12" class="montserrat fw-400 text-center mou-caption-1">
						Take a peek into the first Gelato Lab in Yogyakarta, where the real artisan gelato is made specially for you.
				</v-col>
				<v-col cols="1"></v-col>
		</v-row>

		<v-row class="mb-10">
			<v-col cols="1"></v-col>

			<v-col cols="10" class="pa-0">

				<v-carousel v-if="$vuetify.breakpoint.xs" v-model="gallerySlider" hide-delimiters hide-delimiter-background height="400px" v-resize="onResize">
					<v-carousel-item v-for="(slider, galleryIndex) in (galleryList.length%2 > 0) ? (Math.floor(galleryList.length/2) + 1) : (galleryList.length/2)" :key="galleryIndex">
						<v-row class="d-flex flex-wrap flex-column align-center" style="min-height:400px;max-height:400px;">
							<v-col class="pa-2" 
								v-for="(img,imgIndex) in (galleryList.length - (2*(slider-1)))" :key="imgIndex"
								:cols="((galleryList.length - (2*(slider-1))) == 1) ? '12' : '12'"
								:style="
									((galleryList.length - (2*(slider-1))) == 1) ? 'height:400px;min-height:400px;max-height:400px' :
									'height:200px;min-height:200px;max-height:200px;'
								"
							>
								<v-hover>
									<template v-slot:default="{ hover }">
										<v-card class="cursor-pointer" height="100%" width="100%">
											<v-img class="labImage" height="100%" width="100%" 
												:src="imgPath.path+galleryList[((galleryIndex*2) + imgIndex)].imgId"
												@click="openGallery(((galleryIndex*2) + imgIndex))"
											>
												<v-expand-transition>
													<div
														v-if="hover"
														class="p-absolute d-flex align-center justify-center v-card--reveal white--text mou-caption-1 w-100"
														style="height: 70px;bottom:0;background-color: rgba(255, 255, 255, .7);"
													>
														<v-btn dark small class="mou-highlight">More Info</v-btn>
													</div>
												</v-expand-transition>
											</v-img>
										</v-card>
									</template>
								</v-hover>
							
							</v-col>
						</v-row>

					</v-carousel-item>
				</v-carousel>
				
				<v-carousel v-if="$vuetify.breakpoint.sm" v-model="gallerySlider" hide-delimiters hide-delimiter-background height="400px" v-resize="onResize">
					<v-carousel-item v-for="(slider, galleryIndex) in (galleryList.length%4 > 0) ? (Math.floor(galleryList.length/4) + 1) : (galleryList.length/4)" :key="galleryIndex">
						<v-row class="d-flex flex-wrap flex-column align-center" style="min-height:400px;max-height:400px;">
							<v-col class="pa-2" 
								v-for="(img,imgIndex) in (galleryList.length - (4*(slider-1)))" :key="imgIndex"
								:cols="((galleryList.length - (4*(slider-1))) == 1) ? '12' : '6'"
								:style="
									((galleryList.length - (4*(slider-1))) == 1) ? 'height:400px;min-height:400px;max-height:400px' :
									((galleryList.length - (4*(slider-1))) == 2) ? 'height:400px;min-height:400px;max-height:400px' :
									((galleryList.length - (4*(slider-1))) == 3) && (imgIndex%3)==0 ? 'height:400px;min-height:400px;max-height:400px' :
									'height:200px;min-height:200px;max-height:200px;'
								"
							>
								<v-hover>
									<template v-slot:default="{ hover }">
										<v-card class="cursor-pointer" height="100%" width="100%">
											<v-img class="labImage" height="100%" width="100%" 
												:src="imgPath.path+galleryList[((galleryIndex*4) + imgIndex)].imgId"
												@click="openGallery(((galleryIndex*4) + imgIndex))"
											>
												<template v-slot:placeholder>
													<v-row
														class="fill-height ma-0"
														align="center"
														justify="center"
													>
														<v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
													</v-row>
												</template>
												<v-expand-transition>
													<div
														v-if="hover"
														class="p-absolute d-flex align-center justify-center v-card--reveal white--text mou-caption-1 w-100"
														style="height: 70px;bottom:0;background-color: rgba(255, 255, 255, .7);"
													>
														<v-btn dark small class="mou-highlight">More Info</v-btn>
													</div>
												</v-expand-transition>
											</v-img>
										</v-card>
									</template>
								</v-hover>
							
							</v-col>
						</v-row>

					</v-carousel-item>
				</v-carousel>

				<v-carousel v-if="$vuetify.breakpoint.mdAndUp" v-model="gallerySlider" hide-delimiters hide-delimiter-background height="400px" v-resize="onResize">
					<v-carousel-item v-for="(slider, galleryIndex) in (galleryList.length%6 > 0) ? (Math.floor(galleryList.length/6) + 1) : (galleryList.length/6)" :key="galleryIndex">
						<v-row class="d-flex flex-wrap flex-column align-center" style="min-height:400px;max-height:400px;">
							<v-col class="pa-2" 
								v-for="(img,imgIndex) in (galleryList.length - (6*(slider-1)))" :key="imgIndex"
								:cols="((galleryList.length - (6*(slider-1))) < 4) ? '6' : '3'"
								:style="
									((galleryList.length - (6*(slider-1))) < 3) ? 'height:400px;min-height:400px;max-height:400px' :
									((galleryList.length - (6*(slider-1))) == 4) ? 'height:400px;min-height:400px;max-height:400px' :
									((galleryList.length - (6*(slider-1))) == 5) && (imgIndex==4) ? 'height:400px;min-height:400px;max-height:400px' :
									((galleryList.length - (6*(slider-1))) == 5) && (imgIndex%3)==0 ? 'height:400px;min-height:400px;max-height:400px' :
									(imgIndex%3)==0 ? 'height:400px;min-height:400px;max-height:400px;' : 'height:200px;min-height:200px;max-height:200px;'
								"
							>
								<v-hover>
									<template v-slot:default="{ hover }">
										<v-card class="cursor-pointer" height="100%" width="100%">
											<v-img class="labImage" height="100%" width="100%" 
												:src="imgPath.path+galleryList[((galleryIndex*6) + imgIndex)].imgId"
												@click="openGallery(((galleryIndex*6) + imgIndex))"
											>
												<template v-slot:placeholder>
													<v-row
														class="fill-height ma-0"
														align="center"
														justify="center"
													>
														<v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
													</v-row>
												</template>
												<v-expand-transition>
													<div
														v-if="hover"
														class="p-absolute d-flex align-center justify-center v-card--reveal white--text mou-caption-1 w-100"
														style="height: 70px;bottom:0;background-color: rgba(255, 255, 255, .7);"
													>
														<v-btn dark small class="mou-highlight">More Info</v-btn>
													</div>
												</v-expand-transition>
											</v-img>
										</v-card>
									</template>
							</v-hover>
							
							</v-col>
						</v-row>

					</v-carousel-item>
				</v-carousel>

			</v-col>
			
			<v-col cols="1"></v-col>
		</v-row>

	</v-container>
</template>

<script>
	import config from '../../../config';
	import { mapState, mapActions } from 'vuex'
	import Gallery from './Dialog-Gallery';


	export default {
		components: {
			Gallery
		},
		
		data: () => ({
			overlay: false,
			gallerySlider: 0,
			maxGalleryView: 0,
			imgPath: {
        path: config.STORAGE_URL+'/gallery/',
        lazyPath: config.IMG_LAZY,
        imgEmpty: config.IMG_EMPTY,
        imgError: config.IMG_ERROR
      },
		}),

		computed: mapState({
			galleryList: state => state.gallery.gallery,
		}),

		mounted () {
      this.getGallery();
			this.onResize();
		},
		
		methods:{
			...mapActions({
        getGallery: 'gallery/getGallery'
			}),
			
			openGallery(index){
				this.$refs.galleryRef.openGallery(index);
			},

			onResize () {
				const breakpoint = this.$vuetify.breakpoint;
				switch(breakpoint.name){
					case 'xs':
						this.maxGalleryView = 3;
						break;
					case 'sm':
						this.maxGalleryView = 6;
						break;
					case 'md':
						this.maxGalleryView = 6;
						break;
					case 'lg':
						this.maxGalleryView = 6;
						break;
					default:
						this.maxGalleryView = 6;
						break;
				}
			},
		}

	}
</script>