<template>

	<v-container fluid fill-height class="h-100vh mou-primary pa-0" ref="jumbotron" style="min-height:560px">

		<v-flex v-if="$vuetify.breakpoint.mdAndUp" class="w-100 z-index-2 p-absolute px-10 py-5 montserrat fw-600 mou-caption-1" style="top: 0;">
			<v-row>
				<v-col cols="auto" class="px-5">
					<span class="borderLeftRight mou-normal--text cursor-pointer" @click="goTo('page-jumbotron')">Home</span>
				</v-col>
				<v-col cols="auto" class="px-5">
					<span class="borderLeftRight mou-normal--text cursor-pointer" @click="goTo('page-about')">About Us</span>
				</v-col>
				<v-col cols="auto" class="px-5">
					<span class="borderLeftRight mou-normal--text cursor-pointer" @click="goTo('page-menu')">Menus</span>
				</v-col>
				<v-col cols="auto" class="px-5">
					<span class="borderLeftRight mou-normal--text cursor-pointer" @click="goTo('page-lab')">The Labs</span>
				</v-col>
				<v-col cols="auto" class="px-5">
					<span class="borderLeftRight mou-normal--text cursor-pointer" @click="goTo('page-location')">Contact Us</span>
				</v-col>
				<v-spacer></v-spacer>
				<v-col cols="auto" class="px-5">
					<v-img
                    	src="/assets/logo/logo-black.png"
						width="150px"
						height="auto"
						contain eager
					>

					</v-img>
				</v-col>
			</v-row>
		</v-flex>

		<v-flex class="w-100 z-index-1 p-absolute h-100 align-center justify-center d-flex">
			<div cols="12" class="playfair white--text italic fw-700 text-center mou-jumbotron">Móu Gelato</div>
		</v-flex>

		<v-flex class="d-flex justify-center align-center h-100 w-100">
			<v-img
				src="/assets/jumbotron/jumbotron.png"
				width="100%"
				:height="$vuetify.breakpoint.xs ? '50%' : 
						$vuetify.breakpoint.sm ? '60%' : 
						$vuetify.breakpoint.md ? '70%' : 
						$vuetify.breakpoint.lg ? '80%' : '80%'
				"
				:contain="!$vuetify.breakpoint.xs"
			></v-img>
		</v-flex>

		<v-flex class="p-absolute w-100 z-index-2" style="bottom:-10px;">
			<v-img eager src="/assets/brush/brush-green.svg"></v-img>
		</v-flex>
			

	</v-container>
</template>


<script>
	import * as easings from 'vuetify/es5/services/goto/easing-patterns'

  export default {

	data: () => ({
		windowHeight: null,

	}),

	methods: {
		goTo(targetRef){
			const scrollOption = {
				duration: 1000,
				offset: this.$vuetify.breakpoint.smAndDown ? 0 : 90,
				easing: 'easeInOutCubic',
			}
			
	  		this.$vuetify.goTo('#'+targetRef, scrollOption)
		},
	},

	mounted(){
		window.addEventListener('resize', () => {
			this.windowHeight = window.innerHeight
		})
	},
  
  }
</script>