import axios from 'axios'
import config from '../config';

export default {

  getInstagramFeeds(){
    return axios.get(config.INSTAGRAM_API_URL,{
      params: {
        fields:"id,media_type,media_url,caption",
        limit:12,
        access_token:config.INSTAGRAM_TOKEN
      }
    });
  },
}