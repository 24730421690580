<template>
	<v-container fluid class="py-10 white" ref="contact">

        <v-row>
            <v-col cols="1" lg="2"></v-col>

            <v-col cols="10" sm="5" md="4" lg="3">
                <v-row v-if="contact.address">
                    <v-col cols="12" class="playfair mou-pretitle fw-600">Our Store</v-col>
                    <v-col cols="12" class="montserrat mou-caption-1 pt-0">{{contact.address}}</v-col>
                    <v-col cols="12" class="montserrat mou-caption-1 pt-0 mt-4" v-if="contact.openHour">Open Store : {{contact.openHour}}</v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" class="playfair mou-pretitle fw-600">Email</v-col>
                    <v-col cols="12" class="montserrat mou-caption-1 pt-0"><v-icon class="mr-1 mdi mdi-email-outline"></v-icon> info@mougelato.com</v-col>
                    <v-col cols="12" class="montserrat mou-caption-1 pt-0"><v-icon class="mr-1 mdi mdi-email-outline"></v-icon> sales@mougelato.com</v-col>
                </v-row>

                <v-row v-if="contact.contact_number && contact.contact_number.length > 0">
                    <v-col cols="12" class="playfair mou-pretitle fw-600">Phone</v-col>
                    <v-col cols="12" class="montserrat mou-caption-1 pt-0" v-for="contactNumber in contact.contact_number" :key="contactNumber.id">
                        <v-icon class="mr-1" :class="contactNumber.icon"></v-icon> {{contactNumber.number}} {{contactNumber.detail}}
                    </v-col>
                </v-row>

                <v-row v-if="contact.social_media && contact.social_media.length > 0">
                    <v-col cols="12" class="playfair mou-pretitle fw-600">Social Media</v-col>
                    <v-col cols="12" class="montserrat mou-caption-1 pt-0">
                        <v-tooltip bottom v-for="socialMedia in contact.social_media" :key="socialMedia.id">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn :href="socialMedia.url" fab small dark color="mou-secondary" class="mr-1" v-bind="attrs" v-on="on">
                                    <v-icon :class="socialMedia.icon"></v-icon>
                                </v-btn>
                            </template>
                            <span>{{socialMedia.account}}</span>
                        </v-tooltip>
                    </v-col>
                </v-row>
            </v-col>

            <v-col cols="1" class="d-sm-none"></v-col>
            <v-col cols="1" class="d-sm-none" style="height:300px;"></v-col>

            <v-col cols="10" sm="5" md="6" lg="5">
                <iframe 
                    width="100%" 
                    height="100%" 
                    frameborder="0" 
                    scrolling="no" 
                    marginheight="0" 
                    marginwidth="0" 
                    src="https://maps.google.com/maps?q=-7.7661380,110.4058600&hl=id&z=14&amp;output=embed"
                >
                </iframe>
            </v-col>

            <v-col cols="1" lg="2"></v-col>
        </v-row>

	</v-container>
</template>

<script>
	import { mapState, mapActions } from 'vuex'

  	export default {
        computed: {
            ...mapState({
                contact: state => state.contact.contact,
            }),
        },

        mounted(){
            this.getContact();
        },

        methods: {
            ...mapActions({
                getContact: 'contact/getContact'
            }),
        }
  	}
</script>