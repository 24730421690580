<template>
	<v-container fluid class="py-10 white">
		<v-row>
			<v-col cols="12" class="playfair mou-title fw-700 text-center">Behind The Recipe</v-col>
		</v-row>

        <v-row>
            <v-col cols="1" lg="4"></v-col>
            <v-col cols="10" lg="4" class="montserrat fw-400 text-center mou-subtitle">
							The real artisan gelato made from original recipe and dedicated team who seeks for authenticity.
            </v-col>
            <v-col cols="1" lg="4"></v-col>
        </v-row>

		<v-row>
			<v-col cols="1" sm="1" lg="2"></v-col>

			<v-col cols="10" sm="10" lg="8" class="p-relative" v-if="teamList.length > 0">

				<carousel :items="4" :nav="false" :loop="false" :dots="false" 
					:responsive="{
						0:{items:teamList.length < 1 ? teamList.length : 1},
						600:{items:teamList.length < 2 ? teamList.length : 2},
						960:{items:teamList.length < 4 ? teamList.length : 4}
						}"
				>	

					<template slot="prev">
						<v-btn fab small class="mou-highlight white--text p-absolute z-index-2"
							style="left: 10px; top: 50%"
							v-if="
								$vuetify.breakpoint.xs ? teamList.length > 1 :
								$vuetify.breakpoint.sm ? teamList.length > 2 :
								teamList.length > 4
							"
						>
							<v-icon>mdi mdi-chevron-left</v-icon>
						</v-btn>
					</template>

					<template slot="next">
						<v-btn fab small class="mou-highlight white--text p-absolute z-index-1"
							style="right: 10px; top: 50%"
							v-if="
								$vuetify.breakpoint.xs ? teamList.length > 1 :
								$vuetify.breakpoint.sm ? teamList.length > 2 :
								teamList.length > 4
							"
						>
							<v-icon>mdi mdi-chevron-right</v-icon>
						</v-btn>
					</template>

					<v-col cols="12" v-for="team in teamList" :key="team.id">
						<v-card light class="mx-auto pa-5" hover>
							<v-row>

								<v-col cols="12" class="d-flex justify-center mb-10">
									<div class="mou-primary pa-1" 
										style="border-radius:100%;"
										:style="$vuetify.breakpoint.xs ? {width:'100px',height:'100px'} : {width:'140px',height:'140px'}"
									>
										<v-avatar width="100%" height="100%">
											<v-img class="teamImage" :src="imgPath.path+team.imgId">
												<template v-slot:placeholder>
													<v-row
														class="fill-height ma-0"
														align="center"
														justify="center"
													>
														<v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
													</v-row>
												</template>
											</v-img>
										</v-avatar>
									</div>
								</v-col>

								<v-col cols="12" class="d-flex justify-center pa-0 mb-2">
									<span class="playfair mou-secondary--text mou-subtitle fw-800">{{team.name}}</span>
								</v-col>

								<v-col cols="12" class="d-flex justify-center pa-0 mb-10 text-center">
									<span class="montserrat mou-normal--text mou-caption-1 fw-700">{{team.title}}</span>
								</v-col>

								<v-col cols="12" class="d-flex justify-center pa-0 flex-wrap">
									<v-btn icon small dark color="blue-grey" class="mr-1" v-if="team.social_media.length === 0" style="visibility:hidden;">
										<v-icon>mdi mdi-instagram</v-icon>
									</v-btn>

									<v-tooltip bottom v-for="socialMedia in team.social_media" :key="socialMedia.id">
										<template v-slot:activator="{ on, attrs }">
											<v-btn :href="socialMedia.url" icon small dark color="blue-grey" class="mr-1" v-bind="attrs" v-on="on">
													<v-icon>{{socialMedia.icon}}</v-icon>
											</v-btn>
										</template>
										<span>{{socialMedia.account}}</span>
									</v-tooltip>
								</v-col>

							</v-row>
						</v-card>
					</v-col>

				</carousel>

			</v-col>
			<v-col cols="1" sm="1" lg="2"></v-col>
		</v-row>

	</v-container>
</template>

<script>
	import config from '../../../config';
	import { mapState, mapActions } from 'vuex'
	import carousel from 'vue-owl-carousel'

	export default {
		components: { carousel },
		
		data: () => ({
			imgPath: {
        path: config.STORAGE_URL+'/team/',
        lazyPath: config.IMG_LAZY,
        imgEmpty: config.IMG_EMPTY,
        imgError: config.IMG_ERROR
      },
		}),

		computed: mapState({
			teamList: state => state.team.team,
		}),

    mounted(){
      this.getTeam();
    },

    methods: {
      ...mapActions({
        getTeam: 'team/getTeam'
      }),
    }
		
	}
</script>