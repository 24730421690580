<template>

  <v-dialog v-model="isFormOpened" persistent max-width="600px">

    <v-snackbar :color="snackbar.color" v-model="snackbar.show" timeout="5000">
      <span>{{snackbar.message}}</span>
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar.show = false">Close</v-btn>
      </template>
    </v-snackbar>

    <v-form ref="form" v-model="isFormValid" lazy-validation>
      <v-card>

        <!-- TOOLBAR -->
        <v-toolbar dark color="mou-highlight">
          <!-- TITLE -->
          <v-toolbar-title v-if="action == 'add'">Add New Team</v-toolbar-title>
          <v-toolbar-title v-if="action == 'edit'">Edit Team</v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- CLOSE BUTTON -->
          <v-btn :disabled="isLoadingSubmit" icon dark @click="isFormOpened = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-progress-linear :style="isLoadingSubmit ? '' : 'visibility:hidden'" indeterminate color="secondary"></v-progress-linear>

        <!-- FORM INPUT -->
        <v-card-text>
          <v-container>
            <v-row>

              <!-- PHOTO -->
              <v-col cols="12" class="d-flex justify-center">
                <v-hover v-slot:default="{ hover }">
                  <div class="d-flex cursor-pointer p-relative" @click="openImageUpload()">
                    <v-img height="120px" width="120px" style="border-radius:100%"
                      :src="isImageError ? imgPath.imgError : formData.imgSrc"
                      :lazy-src="imgPath.lazyPath"
                      v-on:error="isImageError = true"
                    >
                    </v-img>
                    <div class="p-absolute d-flex w-100 h-100 justify-center align-center" v-if="hover">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn fab small dark color="blue-grey" class="mr-1" v-bind="attrs" v-on="on">
                              <v-icon>mdi mdi-image-search</v-icon>
                          </v-btn>
                        </template>
                        <span>Change Image</span>
                      </v-tooltip>
                    </div>
                  </div>
                </v-hover>
              </v-col>
              <v-col cols="12" class="pa-0 pt-3" style="margin-top:-70px;">
                <v-row class="ma-0">
                  <v-col cols="3" class="pa-0"></v-col>
                  <v-col cols="6" class="pa-0">
                    <v-file-input :disabled="isLoadingSubmit" class="imageInput" ref="imageInput" v-on:change="onImagePicked($event)" :rules="imageRules" accept="image/png, image/jpeg, image/jpg"></v-file-input>
                  </v-col>
                  <v-col cols="3" class="pa-0"></v-col>
                </v-row>
              </v-col>

              <!-- NAME -->
              <v-col cols="12">
                <v-text-field :disabled="isLoadingSubmit" v-model="formData.name" :rules="nameRules" label="Name*" required></v-text-field>
              </v-col>

              <!-- TITLE -->
              <v-col cols="12">
                <v-text-field :disabled="isLoadingSubmit" v-model="formData.title" :rules="titleRules" label="Title*" required></v-text-field>
              </v-col>

              <!-- POSITION -->
              <v-col cols="12" class="d-flex align-center">
                <v-text-field :disabled="isLoadingSubmit" v-model="formData.position" :rules="positionRules" min=1 type="number" label="Position*" required></v-text-field>
                <v-btn :disabled="isLoadingSubmit" icon @click="formData.position > 1 ? formData.position-- : 1"><v-icon>mdi-minus-circle</v-icon></v-btn>
                <v-btn :disabled="isLoadingSubmit" icon @click="formData.position++" ><v-icon>mdi-plus-circle</v-icon></v-btn>
              </v-col>

            </v-row>

            <v-row class="mt-5">
              <v-col cols="6" class="fw-500 d-flex align-center">SOCIAL MEDIA</v-col>
              <v-col cols="6" class="text-right">
                <v-btn :disabled="isLoadingSubmit" small color="mou-highlight" :dark="!isLoadingSubmit" @click="addSocial()">
                  <v-icon small class="mr-2">mdi mdi-plus</v-icon> Social Media
                </v-btn>
              </v-col>
            </v-row>
            <v-divider></v-divider>

            <v-row>
              <v-col cols="12">
                <v-card outlined="" class="mb-2 px-5" v-for="(social, index) in formData.social_media " :key="social.id">
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field :disabled="isLoadingSubmit" v-model="social.account" :rules="accountRules" label="Account*" required></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field :disabled="isLoadingSubmit" v-model="social.url" :rules="urlRules" label="URL*" required></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-select :disabled="isLoadingSubmit" menu-props="auto" label="Icon*" required :prepend-icon="social.icon" :rules="iconRules"
                        v-model="social.icon" :items="iconSelector" item-text="name" item-value="icon"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="6" class="d-flex align-center">
                      <v-text-field :disabled="isLoadingSubmit" v-model="social.position" :rules="positionRules" min=1 type="number" label="Position*" required></v-text-field>
                      <v-btn :disabled="isLoadingSubmit" icon @click="social.position > 1 ? social.position-- : 1"><v-icon>mdi-minus-circle</v-icon></v-btn>
                      <v-btn :disabled="isLoadingSubmit" icon @click="social.position++" ><v-icon>mdi-plus-circle</v-icon></v-btn>
                    </v-col>
                    <v-col cols="12" class="text-right pt-0">
                      <v-btn :disabled="isLoadingSubmit" small outlined color="error" @click="removeSocial(index)">Remove</v-btn>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>

            

          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="isLoadingSubmit" color="grey darken-1" text @click="isFormOpened = false;">Cancel</v-btn>
          <v-btn :disabled="isLoadingSubmit" color="mou-highlight" text @click="formSave()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>

</template>

<script>
  import config from '../../config';
  export default {

    data: () => ({
      imgPath: {
        path: config.STORAGE_URL+'/team/',
        lazyPath: config.IMG_LAZY,
        imgEmpty: config.IMG_EMPTY,
        imgError: config.IMG_ERROR
      },

      action: null,
      isImageError: false,
      isFormOpened: false,
      isFormValid: true,
      isLoadingSubmit: false,
      snackbar: { show: false, color: '', message: ''},

      formData: {
        id: null,
        imgId: null,
        imgSrc: null,
        imgObj: null,
        name: null,
        title: null,
        position: null,
        social_media: []
      },

      iconSelector:[
        {name:'Instagram', icon:'mdi mdi-instagram'},
        {name:'Facebook', icon:'mdi mdi-facebook'},
        {name:'Twitter', icon:'mdi mdi-twitter'},
        {name:'Youtube', icon:'mdi mdi-youtube'},
        {name:'LinkedIn', icon:'mdi mdi-linkedin'},
        {name:'Pinterest', icon:'mdi mdi-pinterest'},
        {name:'Snapchat', icon:'mdi mdi-snapchat'},
      ],

      imageRules: [ 
        v => !v || v.size < 10000000 || 'Image size should be less than 10 MB!'
      ],
      nameRules: [ 
        v => (!!v) || 'Name is required', 
        v => (v && !!v.trim()) || 'Name is required', 
        v => (v && v.trim().length > 3) || 'Name must be more than 3 characters'
      ],
      titleRules: [ 
        v => !!v|| 'Title is required', 
        v => (v && !!v.trim()) || 'Title is required', 
        v => (v && v.trim().length > 3) || 'Title must be more than 3 characters'
      ],
      positionRules: [ 
        v => (!isNaN(parseInt(v))) || 'Position must be a number', 
        v => (!isNaN(parseInt(v)) && v > 0) || 'Position must be greater than 0', 
        v => (!isNaN(parseInt(v)) && (v%1 === 0)) || 'Position cannot be a decimal', 
      ],
      accountRules: [ 
        v => !!v|| 'Account is required', 
        v => (v && !!v.trim()) || 'Account is required', 
        v => (v && v.trim().length > 3) || 'Account must be more than 3 characters'
      ],
      urlRules: [ 
        v => !!v|| 'URL is required', 
        v => (v && !!v.trim()) || 'URL is required', 
        v => (v && v.trim().length > 3) || 'URL must be more than 3 characters'
      ],
      iconRules: [ 
        v => !!v|| 'Icon is required', 
      ],
    }),

    methods: {
      initVariable(){
        this.action = 'add';
        this.isImageError = false;
        this.formData.id = null;
        this.formData.imgId = null;
        this.formData.imgSrc = this.imgPath.imgEmpty;
        this.formData.imgObj = null;
        this.formData.name = null;
        this.formData.title = null;
        this.formData.position = 1;
        this.formData.social_media = [];
      },

      addSocial(){
        const social_media = {
          id: null,
          position: 1,
          account: null,
          url: null,
          icon: null
        }
        this.formData.social_media.unshift(social_media)
      },

      removeSocial(index){
        this.formData.social_media.splice(index, 1);
      },

      openForm(action, item){
        if(!action){
          return;
        }

        this.initVariable();
        if(action == 'edit' && item){
          this.action = 'edit';
          this.formData.id = item.id;
          this.formData.imgId = item.imgId;
          this.formData.imgSrc = item.imgId ? this.imgPath.path+item.imgId : this.imgPath.imgEmpty;
          this.formData.imgObj = null;
          this.formData.name = item.name;
          this.formData.title = item.title;
          this.formData.position = item.position;
          this.formData.social_media = JSON.parse(JSON.stringify(item.social_media));
        }
        this.isFormOpened = true;
        this.$nextTick(() => this.$refs.form.resetValidation())
      },

      formSave(){
        this.isLoadingSubmit = true;

        if(this.$refs.form.validate()){
          const target = (this.action === 'edit') ? 'backend_team/updateTeam' :  'backend_team/addTeam';
          this.$store.dispatch(target, this.formData).then((data) => {
            this.isLoadingSubmit = false;
            if(data.errors){
              this.callSnackbar('error',data.errors);
              return;
            }
            this.$emit('formTeamSaved',data)
            this.isFormOpened = false;  
          });
          return;
        }

        this.callSnackbar('error','Please fill all the required field');
        this.isLoadingSubmit = false;
      },

      openImageUpload(){
        this.$refs.imageInput.$children[0].$el.click();
      },

      onImagePicked(event){
        if(event){
          const fileReader = new FileReader();
          fileReader.readAsDataURL(event);

          fileReader.onload = (e) => {
            this.formData.imgObj = e.target.result;
          }

          this.isImageError = false;
          this.formData.imgSrc = URL.createObjectURL(event);
        }
      },

      callSnackbar(color,message){
        this.snackbar.message = message;
        this.snackbar.color = color;
        this.snackbar.show = true;
      }

    },

  }
</script>