import axios from 'axios'
import config from '../config';

const api = axios.create({
    baseURL: config.FRONTEND_API_URL
})

export default {
    // USER
    getUser(){
        return api.get('user');
    },

    // CATEGORY
    getCategory(){
        return api.get('category');
    },

    // MENU
    getMenu(){
        return api.get('menu');
    },

    // TESTIMONIAL
    getTestimonial(){
        return api.get('testimonial');
    },

    // GALLERY
    getGallery(){
        return api.get('gallery');
    },

    // TEAM
    getTeam(){
        return api.get('team');
    },

    // CONTACT
    getContact(){
        return api.get('contact');
    },
}