<template>
  <v-container fill-height fluid class="overflow-auto px-7 pb-7">

    <v-snackbar :color="snackbar.color" v-model="snackbar.show" timeout="5000">
      <span>{{snackbar.message}}</span>
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar.show = false">Close</v-btn>
      </template>
    </v-snackbar>
    
    <v-row class="justify-center align-center">
      <v-col cols="12" class="d-flex">
        <v-img src="/assets/logo/logo-color.png" height="100" width="100" contain eager></v-img>
      </v-col>

      <v-col cols="12" class="d-flex justify-center">
        <v-progress-circular
          :size="20"
          color="mou-secondary"
          indeterminate
          :style="isLoadingSubmit ? '' : 'visibility:hidden'"
        ></v-progress-circular>
      </v-col>

      <v-col cols="12">
        <v-row>
          <v-col cols="2" md="3" lg="4" v-if="!$vuetify.breakpoint.xs"></v-col>

          <v-col cols="12" sm="8" md="6" lg="4">
              <v-form ref="form" v-model="isFormValid" lazy-validation>
                <v-row class="px-10">
                  <v-col cols="12">
                    <v-text-field required solo
                      :disabled="isLoadingSubmit" 
                      v-model="formData.username" 
                      :rules="emailRules" 
                      label="Email" 
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field required solo
                      :disabled="isLoadingSubmit"
                      v-model="formData.password"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showPassword ? 'text' : 'password'"
                      :rules="passwordRules"
                      label="Password"
                      @click:append="showPassword = !showPassword"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-btn :disabled="isLoadingSubmit" :dark="!isLoadingSubmit" block @click="formLogin()">LOGIN</v-btn>
                  </v-col>
                </v-row>
              </v-form>
          </v-col>

          <v-col cols="2" md="3" lg="4" v-if="!$vuetify.breakpoint.xs"></v-col>
        </v-row>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
  import config from '../../config';
  import { mapState, mapGetters, mapActions } from 'vuex';

  export default {
    data: () => ({
      isFormValid: true,
      isLoadingSubmit: false,
      snackbar: { show: false, color: '', message: ''},

      showPassword: false,

      formData: {
        username: null,
        password: null,
      },

      emailRules: [ v => !!v || 'E-mail is required', v => /.+@.+\..+/.test(v) || 'E-mail must be valid'],
      passwordRules: [ v => !!v || 'Password is required'],

    }),

    mounted(){
    },

    methods: {

      formLogin(){
        this.isLoadingSubmit = true;

        if(this.$refs.form.validate()){
          this.$store.dispatch('backend_auth/login', this.formData).then((data) => {
            this.isLoadingSubmit = false;
            if(data.errors){
              this.callSnackbar('error','Email or Password are incorrect');
              return;
            }
            this.$router.push({name:'AdminProfile'}).catch(()=>{});
          });
          return;
        }

        this.callSnackbar('error','Please fill all the required field');
        this.isLoadingSubmit = false;
      },

      callSnackbar(color,message){
        this.snackbar.message = message;
        this.snackbar.color = color;
        this.snackbar.show = true;
      }

    }

  }
</script>