<template>
  <v-container fill-height fluid class="overflow-auto px-7 pb-7">

    <FormTeam ref="formTeam" @formTeamSaved="createUpdateData"></FormTeam>
    <DialogDeleteConfirmation ref="dialogDeleteConfirmation" @dialogDeleteConfirmed="deleteData"></DialogDeleteConfirmation>

    <v-snackbar :color="snackbar.color" v-model="snackbar.show" timeout="5000">
      <span>{{snackbar.message}}</span>
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar.show = false">Close</v-btn>
      </template>
    </v-snackbar>
    
    <v-row class="justify-center align-self-start">
      <v-col cols="12" sm="10" md="10">
        <v-layout column>
          
          <!-- DETAIL -->
          <v-flex>

            <!-- TITLE -->
            <v-row>
              <v-col cols="6">
                <span class="title d-flex"><v-icon class="mr-2">mdi-clipboard-account-outline</v-icon>Team Management</span>
              </v-col>
              <v-col cols="6" class="text-right">
                <v-btn :disabled="isLoadingData" small color="mou-highlight" :dark="!isLoadingData" @click="openFormTeam('add')">
                  <v-icon left small>mdi-plus</v-icon>Add New
                </v-btn>
              </v-col>
            </v-row>

            <v-divider></v-divider>
            <v-progress-linear :style="isLoadingData ? '' : 'visibility:hidden'" indeterminate color="warning"></v-progress-linear>

            <v-row>
              <!-- SEARCH BOX -->
              <v-col cols="12">
                <v-text-field dense solo :disabled="isLoadingData" v-model="searchValue" append-icon="mdi-magnify" label="Search" single-line hide-details>
                </v-text-field>
              </v-col>
            </v-row>

          </v-flex>
          
          <!-- TABLE -->
          <v-flex xs12 class="mt-5" style="height:0px;">
            <v-col cols="12" class="mt-5 mou-grey--text mou-caption-1 text-center" v-if="teamList(this.searchValue).length === 0">
              No Data Available
            </v-col>
            
            <v-expansion-panels v-model="panel">
              <v-slide-y-transition hide-on-leave group class="row ma-0">
                <v-expansion-panel class="mb-4" v-for="(team, index) in teamList(this.searchValue)" :key="index">

                  <v-expansion-panel-header disabled hide-actions class="py-0" style="cursor: auto;">
                    <v-row>

                      <v-col cols="12" sm="11">
                        <v-row>

                          <v-col cols="12" sm="4" md="2" class="d-flex justify-center">
                            <div class="d-flex">
                              <v-img height="90px" width="90px" style="border-radius:100%"
                                :src="team.imgError ? team.imgError : team.imgId ? imgPath.path+team.imgId+refreshImage : imgPath.imgEmpty"
                                :lazy-src="imgPath.lazyPath"
                                v-on:error="team.imgError = imgPath.imgError"
                              >
                                <template v-slot:placeholder>
                                  <v-row class="fill-height ma-0" align="center" justify="center">
                                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </div>
                          </v-col>

                          <v-col cols="12" sm="8" md="10">
                            <v-row class="ma-0">
                              <v-col cols="12" class="pa-0">
                                <v-row class="ma-0">
                                  <v-col cols="6" class="pa-0">
                                    <v-row class="ma-0">
                                      <v-col cols="12" class="pa-0">{{team.name}}</v-col>
                                      <v-col cols="12" class="mou-caption-2 mou-grey--text pa-0">{{team.title}}</v-col>
                                    </v-row>
                                  </v-col>
                                  <v-col cols="6" class="text-right pa-0">
                                    <v-chip small color="mou-secondary" text-color="white">
                                      Position <v-avatar right class="grey lighten-3 black--text">{{team.position}}</v-avatar>
                                    </v-chip>
                                  </v-col>
                                </v-row>
                              </v-col>
                              <v-col cols="12" class="mou-caption-2 fw-300 pa-0 pt-5">{{team.testimonial}}</v-col>
                            </v-row>
                          </v-col>

                        </v-row>
                      </v-col>

                      <v-col cols="2" sm="1" class="text-right" :style="$vuetify.breakpoint.xs ? 'position:absolute;right:0;top:0;' : ''">
                        <v-menu transition="slide-x-transition" bottom left offset-y :nudge-width="100">
                          <template v-slot:activator="{ on }">
                            <v-btn :disabled="isLoadingData" small icon v-on="on">
                              <v-icon small>mdi-dots-vertical</v-icon>
                            </v-btn>
                          </template>

                          <v-list dense>
                            <v-list-item :disabled="isLoadingData"  @click="openFormTeam('edit',team)">
                              <v-list-item-icon class="mr-1"><v-icon small>mdi-pencil-outline</v-icon></v-list-item-icon>
                              <v-list-item-title>Edit</v-list-item-title>
                            </v-list-item>
                            <v-list-item :disabled="isLoadingData" @click="openDialogDeleteConfirmation(team.id,team.name)">
                              <v-list-item-icon class="mr-1"><v-icon small>mdi-trash-can-outline</v-icon></v-list-item-icon>
                              <v-list-item-title>Delete</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-col>

                      <v-col cols="12" class="text-right pt-0">
                        <v-btn text small @click="panel === index ? panel = null : panel = index">
                          <span v-if="panel !== index">
                            View Social Media <v-icon small class="ml-2">mdi mdi-chevron-down</v-icon>
                          </span>
                          <span v-if="panel === index">
                            Hide Social Media <v-icon small class="ml-2">mdi mdi-chevron-up</v-icon>
                          </span>
                        </v-btn>
                      </v-col>

                    </v-row>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content eager>
                    <v-card v-if="team.social_media.length === 0" outlined class="mb-2 px-5 py-2 mou-caption-2">No Social Media</v-card>
                    <v-card outlined class="mb-2 px-5" v-for="social in team.social_media" :key="social.id">
                      <v-row>
                        <v-col cols="2" class="d-flex align-center">
                          <v-icon large>{{social.icon}}</v-icon>
                        </v-col>
                        <v-col cols="10" class="d-flex align-center">
                          <v-row class="ma-0">
                            <v-col cols="12" md="4" class="pa-0 mou-caption-2 d-flex align-center">{{social.account}}</v-col>
                            <v-col cols="12" md="4" class="pa-0 mou-caption-2 d-flex align-center">{{social.url}}</v-col>
                            <v-col cols="12" md="4" class="pa-0 d-flex align-center justify-end">
                              <v-chip small color="mou-secondary" text-color="white">
                                Position <v-avatar right class="grey lighten-3 black--text">{{social.position}}</v-avatar>
                              </v-chip>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-expansion-panel-content>

                </v-expansion-panel>
              </v-slide-y-transition>
            </v-expansion-panels>
          </v-flex>

        </v-layout>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
  import config from '../../config';
  import { mapState, mapGetters, mapActions } from 'vuex';
  import FormTeam from '../../components/Backend/FormTeam';
  import DialogDeleteConfirmation from '../../components/Backend/DialogDeleteConfirmation';

  export default {
    components: {
      FormTeam,
      DialogDeleteConfirmation
    },

    data: () => ({
      isLoadingData: false,
      refreshImage: '',

      snackbar: { show: false, color: '', message: ''},

      imgPath: {
        path: config.STORAGE_URL+'/team/',
        lazyPath: config.IMG_LAZY,
        imgEmpty: config.IMG_EMPTY,
        imgError: config.IMG_ERROR
      },
      searchValue: '',
      panel: null,
    }),

    computed: {
      ...mapState({
        team: state => state.backend_team.team,
      }),
      ...mapGetters({
        teamList: 'backend_team/searchTeam',
      }),
      teamListCount(){
        return this.teamList(this.searchValue).length;
      },
    },

    watch: {
      'teamListCount': {
        handler: function(newValue, oldValue) {
          if(oldValue !== newValue){
            this.panel = null;
          }
        },
        deep: true
      },
    },

    mounted(){
      this.isLoadingData = true;
      this.getTeam().then((response) => {
        if(response.errors){
          this.callSnackbar('error',response.errors);
          return;
        }
        this.isLoadingData = false;
      });
    },

    methods: {
      ...mapActions({
        getTeam: 'backend_team/getTeam'
      }),

      actionRefreshImage(){
        const now = Date.now();
        this.refreshImage = '?'+now;
      },

      openFormTeam(action, item){
        this.$refs.formTeam.openForm(action, item);
      },

      openDialogDeleteConfirmation(id, name){
        this.$refs.dialogDeleteConfirmation.openDialog(id, name);
      },

      createUpdateData(data){
        if(data.errors){
          this.callSnackbar('error',data.errors)
          return;
        }
        this.callSnackbar('success',data.success)
        this.actionRefreshImage()
      },

      deleteData(id){
        this.isLoadingData = true;
        this.$store.dispatch('backend_team/deleteTeam',id).then((data) => {
          this.isLoadingData = false;
          if(data.errors){
            this.callSnackbar('error',data.errors);
            return;
          }
          this.callSnackbar('success',data.success);
                  this.actionRefreshImage()
        });
      },

      callSnackbar(color,message){
        this.snackbar.message = message;
        this.snackbar.color = color;
        this.snackbar.show = true;
      }
    }

  }
</script>