<template>
  <v-container fill-height fluid class="px-7 pb-7">
    
    <FormUser ref="formUser" @formUserSaved="createUpdateData"></FormUser>
    <DialogDeleteConfirmation ref="dialogDeleteConfirmation" @dialogDeleteConfirmed="deleteData"></DialogDeleteConfirmation>

    <v-snackbar :color="snackbar.color" v-model="snackbar.show" timeout="5000">
      <span>{{snackbar.message}}</span>
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar.show = false">Close</v-btn>
      </template>
    </v-snackbar>

    <v-row class="h-100 justify-center align-self-start">
      <v-col  cols="12" sm="12" md="10" class="h-100">
        <v-layout column class="h-100">
      
          <!-- DETAIL -->
          <v-flex>

            <!-- TITLE -->
            <v-row>
              <v-col cols="6">
                <span class="title d-flex"><v-icon class="mr-2">mdi-account-outline</v-icon>User Management</span>
              </v-col>
              <v-col cols="6" class="text-right">
                <v-btn :disabled="isLoadingData" small :dark="!isLoadingData" color="mou-highlight" @click="openFormUser('add')">
                  <v-icon left small>mdi-plus</v-icon>Add User
                </v-btn>
              </v-col>
            </v-row>

            <v-divider></v-divider>
            <v-progress-linear :style="isLoadingData ? '' : 'visibility:hidden'" indeterminate color="warning"></v-progress-linear>

            <v-row>
              <!-- SEARCH BOX -->
              <v-col cols="12">
                  <v-text-field
                  dense
                  solo
                  v-model="searchValue"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  ></v-text-field>
              </v-col>
            </v-row>

          </v-flex>
          
          <!-- TABLE -->
          <v-flex xs12 class="mt-5" style="height:0px;">
            <v-card class="fill-height overflow-hidden">
              
              <v-data-table
                disable-pagination
                :search="searchValue"
                :headers="tableHeader"
                :items="user"
                :fixed-header="true"
                class="elevation-1"
                height="100%"
                style="height:100%"
              >

              <template v-slot:item.actions="{ item }">

                <v-menu v-if="(item.id !== profile.id) && (item.id !== 1)" transition="slide-x-transition" bottom left offset-y :nudge-width="100">
                  <template v-slot:activator="{ on }">
                    <v-btn :disabled="isLoadingData" small icon v-on="on">
                      <v-icon small>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list dense>
                    <v-list-item :disabled="isLoadingData" @click="openFormUser('edit',item)">
                      <v-list-item-icon class="mr-1"><v-icon small>mdi-pencil-outline</v-icon></v-list-item-icon>
                      <v-list-item-title>Edit</v-list-item-title>
                    </v-list-item>
                    <v-list-item :disabled="isLoadingData" @click="openDialogDeleteConfirmation(item.id,item.name)">
                      <v-list-item-icon class="mr-1"><v-icon small>mdi-trash-can-outline</v-icon></v-list-item-icon>
                      <v-list-item-title>Delete</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>

              </template>
              
              </v-data-table>

            </v-card>
          </v-flex>

        </v-layout>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import FormUser from '../../components/Backend/FormUser';
  import DialogDeleteConfirmation from '../../components/Backend/DialogDeleteConfirmation';

  export default {
    components: {
      FormUser,
      DialogDeleteConfirmation
    },

    data: () => ({
      isLoadingData: false,
      snackbar: { show: false, color: '', message: ''},

      searchValue: '',
      tableHeader: [
        { text: 'Email', value: 'email' },
        { text: 'Name', value: 'name' },
        { text: '', value: 'actions', align: 'end', sortable: false },
      ],
    }),

    mounted(){
      this.$store.dispatch('backend_user/getUser');
    },

    computed: {
      ...mapState({
        profile: state => state.backend_profile.profile,
      }),
      user(){
        return this.$store.state.backend_user.user;
      },
    },

    methods: {
      openFormUser: function (action, item){
        this.$refs.formUser.openForm(action, item);
      },

      openDialogDeleteConfirmation: function (id, name){
        this.$refs.dialogDeleteConfirmation.openDialog(id, name);
      },

      createUpdateData(data){
        if(data.errors){
          this.callSnackbar('error',data.errors)
          return;
        }
        this.callSnackbar('success',data.success)
      },

      deleteData(id){
        this.isLoadingData = true;
        this.$store.dispatch('backend_user/deleteUser',id).then((data) => {
          this.isLoadingData = false;
          if(data.errors){
            this.callSnackbar('error',data.errors);
            return;
          }
          this.callSnackbar('success',data.success);
        });
      },

      callSnackbar(color,message){
        this.snackbar.message = message;
        this.snackbar.color = color;
        this.snackbar.show = true;
      }
    }

  }
</script>