
// initial state
const state = () => ({
	menu: [
		{
			id: 'user-management',
			icon: 'mdi-account-outline',
			title: 'User Management',
			link: '/admin/user',
		},
		{
			id: 'category-management',
			icon: 'mdi-clipboard-list-outline',
			title: 'Category Management',
			link: '/admin/category',
		},
		{
			id: 'menu-management',
			icon: 'mdi-clipboard-text-multiple-outline',
			title: 'Menu Management',
			link: '/admin/menu',
		},
		{
			id: 'testimonial-management',
			icon: 'mdi-comment-text-multiple-outline',
			title: 'Testimonial Management',
			link: '/admin/testimonial',
		},
		{
			id: 'gallery-management',
			icon: 'mdi-image-multiple-outline',
			title: 'Gallery Management',
			link: '/admin/gallery',
		},
		{
			id: 'team-management',
			icon: 'mdi-clipboard-account-outline',
			title: 'Team Management',
			link: '/admin/team',
		},
		{
			id: 'contact-management',
			icon: 'mdi-office-building-marker-outline',
			title: 'Contact Management',
			link: '/admin/contact',
		},
	],
})

// getters
const getters = {
	getConfigById: (state) => (id) => {

		function findConfig(object, value) {
			if(object.id && object.id === value){
				return object;
			}

			for(let i = 0; i < object.length; i++ ){
				const found = findConfig(object[i], value);
				if(found){
					return found;
				}

				if(!found && object[i].child){
					const foundChild = findConfig(object[i].child, value)
					if(foundChild){
						return foundChild;
					}
				}
			}
		}

		return findConfig(state.menu, id);
  }
}

// actions
const actions = {}

// mutations
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}