<template>
	<v-container fluid dark class="py-10 white">
		<v-row>
			<v-col cols="12" class="playfair fw-700 text-center mou-title">They Say About Our Taste</v-col>
			<v-col cols="12" class="montserrat fw-400 text-center mou-caption-1">Our special and fresh gelatos has been recognized</v-col>
		</v-row>

		<v-row>
			<v-col cols="2" lg="1" class="d-none d-md-flex"></v-col>

			<v-col cols="12" sm="12" lg="10" class="p-relative" v-if="testimonialList.length > 0">

				<carousel :autoplay="true" :items="2" :nav="false" :loop="true" :dots="false" :responsive="{0:{items:1},600:{items:1},960:{items:1}}">	

					<template slot="prev">
						<v-btn fab small class="blue-grey lighten-2 white--text p-absolute z-index-2"
							style="left: 10px; top: calc(50% - 20px)"
						>
							<v-icon>mdi mdi-chevron-left</v-icon>
						</v-btn>
					</template>

					<template slot="next">
						<v-btn fab small class="blue-grey lighten-2 white--text p-absolute z-index-1"
							style="right: 10px; top: calc(50% - 20px)"
						>
							<v-icon>mdi mdi-chevron-right</v-icon>
						</v-btn>
					</template>

					<v-col cols="12" v-for="testimonial in testimonialList" :key="testimonial.id">
						<v-sheet class="mx-auto" hover :class="$vuetify.breakpoint.xs ? 'pa-0' : 'pa-5'">
							<v-row>
								<v-col cols="12" class="px-sm-2 px-sm-7">
									<v-row>
										<v-col cols="4" md="3" class="d-flex justify-center align-center">
											<div class="mou-primary" 
												:style="$vuetify.breakpoint.xs ? 'width:80px;height:80px;border-radius:100%;' : 'width:100px;height:100px;border-radius:100%;'"
											>
												<v-avatar width="100%" height="100%">
													<v-img :src="imgPath.path+testimonial.imgId">
														<template v-slot:placeholder>
															<v-row
																class="fill-height ma-0"
																align="center"
																justify="center"
															>
																<v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
															</v-row>
														</template>
													</v-img>
												</v-avatar>
											</div>
											
										</v-col>
										<v-col cols="8" md="9" class="d-flex justify-center align-center">
											<v-row>
												<v-col cols="12" md="10">
													<span class="montserrat mou-caption-2 mou-normal--text text-justify">{{testimonial.testimonial}}</span>
												</v-col>
												<v-col cols="12" class="pb-0">
													<span class="mou-secondary--text playfair mou-subtitle fw-700">{{testimonial.name}}</span>
												</v-col>
												<v-col cols="12" class="pt-0">
													<span class="mou-grey--text montserrat fw-500" style="font-size:12px">{{testimonial.title}}</span>
												</v-col>
											</v-row>
										</v-col>
									</v-row>
								</v-col>
							</v-row>
						</v-sheet>
					</v-col>

				</carousel>

			</v-col>

			<v-col cols="2" sm="1" lg="1" class="d-none d-md-flex"></v-col>
		</v-row>

	</v-container>
</template>

<script>
	import config from '../../../config';
	import { mapState, mapActions } from 'vuex'
	import carousel from 'vue-owl-carousel'

	export default {
		components: { carousel },
		
		data: () => ({
			imgPath: {
        path: config.STORAGE_URL+'/testimonial/',
        lazyPath: config.IMG_LAZY,
        imgEmpty: config.IMG_EMPTY,
        imgError: config.IMG_ERROR
      },
		}),

		computed: {
      ...mapState({
        testimonialList: state => state.testimonial.testimonial,
      }),
    },

    mounted(){
      this.getTestimonial();
    },

    methods: {
      ...mapActions({
        getTestimonial: 'testimonial/getTestimonial'
      }),
    }
		
	}
</script>