<template>
    <v-fade-transition>
        <div class="z-index-10 mou-primary w-100 h-100 p-fixed d-flex align-center justify-center" v-if="!rendered">

            <v-row>
                <v-col cols="12" class="d-flex align-center justify-center pa-0">
                    <v-img src="/assets/logo/logo-black.png" width="100px" height="70px" contain eager></v-img>
                </v-col>

                <v-col cols="12" class="d-flex align-center justify-center pa-0" style="width: 100px; height: 100px;z-index:5"> 
                    <div class="self-building-square-spinner">
                        <div class="square"></div>
                        <div class="square"></div>
                        <div class="square"></div>
                        <div class="square clear"></div>
                        <div class="square"></div>
                        <div class="square"></div>
                        <div class="square clear"></div>
                        <div class="square"></div>
                        <div class="square"></div>
                    </div>
                </v-col>

            </v-row>

        </div>
    </v-fade-transition>
</template>

<script>
export default {

    data: () => ({
        rendered: false
    }),

    created(){
        document.onreadystatechange = () => { //To load the app only after all libraries are loaded
            if (document.readyState == "complete") { 
                const mainAppElement = this.$root.$children[0].$children[0].$children[0].$refs['main-application'].$el;
                mainAppElement.classList.remove('p-absolute')
                mainAppElement.classList.remove('overflow-hidden')
                this.rendered = true;
            } 
        }
    }


};
</script>