<template>
  <v-container fill-height fluid class="overflow-auto px-7 pb-7">

    <FormMenu ref="formMenu" @formMenuSaved="createUpdateData"></FormMenu>
    <DialogDeleteConfirmation ref="dialogDeleteConfirmation" @dialogDeleteConfirmed="deleteData"></DialogDeleteConfirmation>

    <v-snackbar :color="snackbar.color" v-model="snackbar.show" timeout="5000">
      <span>{{snackbar.message}}</span>
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar.show = false">Close</v-btn>
      </template>
    </v-snackbar>
    
    <v-row class="justify-center align-self-start">
      <v-col cols="12" sm="10" md="10">
        <v-layout column>
          
          <!-- DETAIL -->
          <v-flex>

            <!-- TITLE -->
            <v-row>
              <v-col cols="6">
                <span class="title d-flex"><v-icon class="mr-2">mdi-clipboard-text-multiple-outline</v-icon>Menu Management</span>
              </v-col>
              <v-col cols="6" class="text-right">
                <v-btn :disabled="isLoadingData" small color="mou-highlight" :dark="!isLoadingData" @click="openFormMenu('add')">
                  <v-icon left small>mdi-plus</v-icon>Add New
                </v-btn>
              </v-col>
            </v-row>

            <v-divider></v-divider>
            <v-progress-linear :style="isLoadingData ? '' : 'visibility:hidden'" indeterminate color="warning"></v-progress-linear>

            <v-row class="d-flex justify-space-between">
              <!-- SEARCH BOX -->
              <v-col cols="12" md="6">
                <v-text-field :disabled="isLoadingData" dense solo v-model="searchValue" append-icon="mdi-magnify" label="Search" single-line hide-details>
                </v-text-field>
              </v-col>

              <!-- FILTER BOX -->
              <v-col cols="12" md="3">
                <v-select clearable solo dense hide-details 
                  :disabled="isLoadingData"
                  v-model="selectedCategory"
                  :items="category"
                  item-text="name"
                  item-value="id"
                  label="Category"
                ></v-select>
              </v-col>
            </v-row>

          </v-flex>
          
          <!-- TABLE -->
          <v-flex xs12 class="mt-2" style="height:0px;">
            <v-row>

              <v-col cols="12" class="mt-5 mou-grey--text mou-caption-1 text-center" v-if="menuList(this.searchValue, this.selectedCategory).length === 0">
                No Data Available
              </v-col>

              <v-slide-y-transition hide-on-leave group class="row ma-0 w-100">

                <v-col cols="12" sm="6" md="4" lg="3" v-for="menu in menuList(this.searchValue, this.selectedCategory)" :key="menu.id">
                  

                  <v-card class="mx-auto p-relative d-flex flex-column align-center" :key="menu.id">
                    <div class="pa-5">
                      <div class="menu-container mou-primary cursor-pointer d-flex justify-center" style="width:180px;height:180px;">
                        <v-avatar width="100%" height="100%">
                          <v-img class="teamImage" height="100%" width="100%" 
                            :src="menu.imgError ? menu.imgError : menu.imgId ? imgPath.path+menu.imgId+random() : imgPath.imgEmpty"
                            :lazy-src="imgPath.lazyPath"
                            v-on:error="menu.imgError = imgPath.imgError"
                          >
                            <template v-slot:placeholder>
                              <v-row class="fill-height ma-0" align="center" justify="center">
                                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-avatar>
                      </div>
                    </div>

                    <div class="p-absolute" style="top:10px;right:10px;">
                      <v-chip small color="mou-secondary" text-color="white">
                        Position <v-avatar right class="grey lighten-3 black--text">{{menu.position}}</v-avatar>
                      </v-chip>
                    </div>

                    <v-card-text style="border-top:1px solid #eee;">
                      <v-row>
                        <v-col class="text--primary fw-500">{{menu.name}}</v-col>
                        <v-col cols="auto" class="d-flex justify-end">
                          <v-chip small color="mou-highlight" text-color="white">
                            {{menu.category.name}}
                          </v-chip>
                        </v-col>
                        <v-col cols="12" class="pt-0" style="white-space: break-spaces;">{{menu.description}}</v-col>
                      </v-row>
                    </v-card-text>

                    

                    

                    <v-card-actions class="d-flex justify-space-between w-100">
                      <v-btn :disabled="isLoadingData" color="error" text @click="openDialogDeleteConfirmation(menu.id,menu.name)">Delete</v-btn>
                      <v-btn :disabled="isLoadingData" color="orange" text @click="openFormMenu('edit',menu)">Edit</v-btn>
                    </v-card-actions>
                  </v-card>

                </v-col>
              </v-slide-y-transition>

            </v-row>
          </v-flex>

        </v-layout>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
  import config from '../../config';
  import { mapState, mapGetters, mapActions } from 'vuex';
  import FormMenu from '../../components/Backend/FormMenu';
  import DialogDeleteConfirmation from '../../components/Backend/DialogDeleteConfirmation';

  export default {
    components: {
      FormMenu,
      DialogDeleteConfirmation
    },

    data: () => ({
      isLoadingData: false,
      snackbar: { show: false, color: '', message: ''},

      imgPath: {
        path: config.STORAGE_URL+'/menu/',
        lazyPath: config.IMG_LAZY,
        imgEmpty: config.IMG_EMPTY,
        imgError: config.IMG_ERROR
      },
      searchValue: '',
      selectedCategory: null,
    }),

    computed: {
      ...mapState({
        menu: state => state.backend_menu.menu,
        category: state => state.backend_category.category,
      }),
      ...mapGetters({
        menuList: 'backend_menu/searchMenu',
      })
    },

    mounted(){
      this.isLoadingData = true;
      this.getMenu().then((response) => {
        if(response.errors){
          this.callSnackbar('error',response.errors);
          return;
        }

        this.getCategory().then((response) => {
          if(response.errors){
            this.callSnackbar('error',response.errors);
            return;
          }
          this.isLoadingData = false;
        });
      });
    },

    methods: {
      ...mapActions({
        getMenu: 'backend_menu/getMenu',
        getCategory: 'backend_category/getCategory'
      }),

      random(){
        const now = Date.now();
        return '?'+now;
      },

      openFormMenu: function (action, item){
        this.$refs.formMenu.openForm(action, item);
      },

      openDialogDeleteConfirmation: function (id, name){
        this.$refs.dialogDeleteConfirmation.openDialog(id, name);
      },

      createUpdateData(data){
        if(data.errors){
          this.callSnackbar('error',data.errors)
          return;
        }
        this.callSnackbar('success',data.success)
      },

      deleteData(id){
        this.isLoadingData = true;
        this.$store.dispatch('backend_menu/deleteMenu',id).then((data) => {
          this.isLoadingData = false;
          if(data.errors){
            this.callSnackbar('error',data.errors);
            return;
          }
          this.callSnackbar('success',data.success);
        });
      },

      callSnackbar(color,message){
        this.snackbar.message = message;
        this.snackbar.color = color;
        this.snackbar.show = true;
      }
    }

  }
</script>