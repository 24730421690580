<template>
  <v-container fill-height fluid class="overflow-auto px-7 pb-7">

    <FormCategory ref="formCategory" @formCategorySaved="createUpdateData"></FormCategory>
    <DialogDeleteConfirmation ref="dialogDeleteConfirmation" @dialogDeleteConfirmed="deleteData"></DialogDeleteConfirmation>

    <v-snackbar :color="snackbar.color" v-model="snackbar.show" timeout="5000">
      <span>{{snackbar.message}}</span>
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar.show = false">Close</v-btn>
      </template>
    </v-snackbar>
    
    <v-row class="justify-center align-self-start">
      <v-col cols="12" sm="10" md="10">
        <v-layout column>
          
          <!-- DETAIL -->
          <v-flex>

            <!-- TITLE -->
            <v-row>
              <v-col cols="6">
                <span class="title d-flex"><v-icon class="mr-2">mdi-clipboard-list-outline</v-icon>Category Management</span>
              </v-col>
              <v-col cols="6" class="text-right">
                <v-btn :disabled="isLoadingData" small color="mou-highlight" dark @click="openFormCategory('add')">
                  <v-icon left small>mdi-plus</v-icon>Add New
                </v-btn>
              </v-col>
            </v-row>

            <v-divider></v-divider>
            <v-progress-linear :style="isLoadingData ? '' : 'visibility:hidden'" indeterminate color="warning"></v-progress-linear>

            <v-row>
              <!-- SEARCH BOX -->
              <v-col cols="12">
                <v-text-field :disabled="isLoadingData" dense solo v-model="searchValue" append-icon="mdi-magnify" label="Search" single-line hide-details>
                </v-text-field>
              </v-col>
            </v-row>

          </v-flex>
          
          <!-- TABLE -->
          <v-flex xs12 class="mt-2" style="height:0px;">
            <v-col cols="12" class="mt-5 mou-grey--text mou-caption-1 text-center" v-if="categoryList(this.searchValue).length === 0">
              No Data Available
            </v-col>

              <v-row>
                <v-slide-x-transition hide-on-leave group class="row ma-0">

                  <v-col cols="12" v-for="category in categoryList(this.searchValue)" :key="category.id">
                    <v-card class="mx-auto">

                      <v-row class="ma-0">

                        <v-col cols="12" sm="11">
                          <v-row>

                            <v-col cols="12" sm="2" md="2" lg="1" class="d-flex justify-center">
                              <div class="d-flex">
                                <div style="height:25px;width:25px">
                                  <img contain width="100%" height="100%"
                                    :src="category.imgError ? category.imgError : category.imgId ? imgPath.path+category.imgId+random() : imgPath.imgEmpty"
                                    :lazy-src="imgPath.lazyPath"
                                    v-on:error="category.imgError = imgPath.imgError"
                                  >
                                </div>
                              </div>
                            </v-col>

                            <v-col cols="12" sm="10" md="10" lg="11">
                              <v-row class="ma-0">
                                <v-col cols="12" class="pa-0">
                                  <v-row class="ma-0">
                                    <v-col cols="12" sm="6" class="pa-0">
                                      <v-row class="ma-0">
                                        <v-col cols="12" class="pa-0 text-center text-sm-left">{{category.name}}</v-col>
                                      </v-row>
                                    </v-col>
                                    <v-col cols="12" sm="6" class="text-center text-sm-right pa-0 mt-5 mt-sm-0">
                                      <v-chip small color="mou-secondary" text-color="white">
                                        Position <v-avatar right class="grey lighten-3 black--text">{{category.position}}</v-avatar>
                                      </v-chip>
                                    </v-col>
                                  </v-row>
                                </v-col>
                              </v-row>
                            </v-col>

                          </v-row>
                        </v-col>

                        <v-col cols="2" sm="1" class="text-right d-flex justify-center align-center" :style="$vuetify.breakpoint.xs ? 'position:absolute;right:0;top:0;' : ''">
                          <v-menu transition="slide-x-transition" bottom left offset-y :nudge-width="100">
                            <template v-slot:activator="{ on }">
                              <v-btn :disabled="isLoadingData" small icon v-on="on">
                                <v-icon small>mdi-dots-vertical</v-icon>
                              </v-btn>
                            </template>

                            <v-list dense>
                              <v-list-item :disabled="isLoadingData" @click="openFormCategory('edit',category)">
                                <v-list-item-icon class="mr-1"><v-icon small>mdi-pencil-outline</v-icon></v-list-item-icon>
                                <v-list-item-title>Edit</v-list-item-title>
                              </v-list-item>
                              <v-list-item :disabled="isLoadingData" @click="openDialogDeleteConfirmation(category.id,category.name)">
                                <v-list-item-icon class="mr-1"><v-icon small>mdi-trash-can-outline</v-icon></v-list-item-icon>
                                <v-list-item-title>Delete</v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </v-col>


                      </v-row>

                    </v-card>

                  </v-col>

                </v-slide-x-transition>
              </v-row>
          </v-flex>

        </v-layout>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
  import config from '../../config';
  import { mapState, mapGetters, mapActions } from 'vuex';
  import FormCategory from '../../components/Backend/FormCategory';
  import DialogDeleteConfirmation from '../../components/Backend/DialogDeleteConfirmation';

  export default {
    components: {
      FormCategory,
      DialogDeleteConfirmation
    },

    data: () => ({
      isLoadingData: false,
      snackbar: { show: false, color: '', message: ''},

      imgPath: {
        path: config.STORAGE_URL+'/category/',
        lazyPath: config.IMG_LAZY,
        imgEmpty: config.IMG_EMPTY,
        imgError: config.IMG_ERROR
      },
      searchValue: '',
    }),

    computed: {
      ...mapState({
        category: state => state.backend_category.category,
      }),
      ...mapGetters({
        categoryList: 'backend_category/searchCategory',
      })
    },

    mounted(){
      this.isLoadingData = true;
      this.getCategory().then((response) => {
        if(response.errors){
          this.callSnackbar('error',response.errors);
          return;
        }
        this.isLoadingData = false;
      });
    },

    methods: {
      ...mapActions({
        getCategory: 'backend_category/getCategory'
      }),

      random(){
        const now = Date.now();
        return '?'+now;
      },

      openFormCategory: function (action, item){
        this.$refs.formCategory.openForm(action, item);
      },

      openDialogDeleteConfirmation: function (id, name){
        this.$refs.dialogDeleteConfirmation.openDialog(id, name);
      },

      createUpdateData(data){
        if(data.errors){
          this.callSnackbar('error',data.errors)
          return;
        }
        this.callSnackbar('success',data.success)
      },

      deleteData(id){
        this.isLoadingData = true;
        this.$store.dispatch('backend_category/deleteCategory',id).then((data) => {
          this.isLoadingData = false;
          if(data.errors){
            this.callSnackbar('error',data.errors);
            return;
          }
          this.callSnackbar('success',data.success);
        });
      },

      callSnackbar(color,message){
        this.snackbar.message = message;
        this.snackbar.color = color;
        this.snackbar.show = true;
      }
    }

  }
</script>