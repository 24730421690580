import api from "../../../api/api";

// initial state
const state = () => ({
	user: []
})

// getters
const getters = {}

// actions
const actions = {
	getUser({commit}){
		return new Promise((resolve,reject) => {
			api.getUser().then(response => {
				commit('SET_USER', response.data)
				resolve({'success':response.data});
			}).catch(error => {
				const errorResponse = error && error.response && error.response.data;
				resolve({'errors':errorResponse && errorResponse.message});
			})
		})
	},

	addUser({commit},user){
		return new Promise((resolve,reject) => {
			api.addUser(user).then(response => {
				commit('ADD_USER', response.data.data)
				resolve(response.data);
			}).catch(error => {
				const errorResponse = error && error.response && error.response.data;
				resolve({'errors':errorResponse && errorResponse.message});
			})
		})
	},

	updateUser({commit},user){
		return new Promise((resolve,reject) => {
			api.updateUser(user.id,user).then(response => {
				commit('UPDATE_USER', response.data.data)
				resolve(response.data);
				}).catch(error => {
					const errorResponse = error && error.response && error.response.data;
					resolve({'errors':errorResponse && errorResponse.message});
				})
		})
	},

	deleteUser({commit},id){
		return new Promise((resolve,reject) => {
			api.deleteUser(id).then(response => {
				commit('DELETE_USER', id)
				resolve(response.data);
			}).catch(error => {
				const errorResponse = error.response.data;
				resolve({'errors':errorResponse.message});
			})
		})
	}
}

// mutations
const mutations = {
	SET_USER(state, user){
		state.user = user;
	},

	ADD_USER(state, user){
		state.user.unshift(user);
	},

	UPDATE_USER(state, user){
		const updateIndex = state.user.findIndex((data) => data	.id === user.id);
		state.user.splice(updateIndex,1,user);
	},

	DELETE_USER(state, id){
		const deleteIndex = state.user.findIndex((data) => data.id === id);
		state.user.splice(deleteIndex, 1);
	}
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}