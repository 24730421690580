<template>
  <v-container fill-height fluid class="overflow-auto px-7 pb-7 d-flex align-baseline">
    
    <v-snackbar :color="snackbar.color" v-model="snackbar.show" timeout="5000">
      <span>{{snackbar.message}}</span>
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar.show = false">Close</v-btn>
      </template>
    </v-snackbar><v-snackbar :color="snackbar.color" v-model="snackbar.show" timeout="5000">
      <span>{{snackbar.message}}</span>
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar.show = false">Close</v-btn>
      </template>
    </v-snackbar>

    <v-form ref="form" class="w-100" v-model="isFormValid" lazy-validation>
      <v-row class="justify-center align-self-start">
        <v-col cols="12" sm="10" md="7" lg="6">
          <v-layout column>
            
            <!-- DETAIL -->
            <v-flex>

              <!-- TITLE -->
              <v-row>
                <v-col cols="12">
                  <span class="title d-flex"><v-icon class="mr-2">mdi-account-circle</v-icon>User Profile</span>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-progress-linear :style="isLoadingData ? '' : 'visibility:hidden'" indeterminate color="warning"></v-progress-linear>

            </v-flex>
            
            <!-- TABLE -->
            <v-flex xs12 class="mt-2" style="height:0px;">
                <v-row>
                  <v-col cols="12">

                    <v-row>
                      <v-col cols="12" class="mou-subtitle fw-500 pb-0">
                        Name
                      </v-col>
                      <v-col cols="12">
                        <v-text-field solo hide-details="auto" :rules="nameRules" :disabled="!isFormEdit" v-model="formData.name"></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" class="mou-subtitle fw-500 pb-0">
                        Email
                      </v-col>
                      <v-col cols="12">
                        <v-text-field solo hide-details="auto" :rules="emailRules" :disabled="!isFormEdit" v-model="formData.email"></v-text-field>
                      </v-col>
                    </v-row>

                    <v-fade-transition hide-on-leave>
                      <v-row v-if="!isChangePassword">
                        <v-col cols="12" sm="6" class="text-center text-sm-left" order="2" order-sm="1">
                          <v-btn small :block="$vuetify.breakpoint.xs" @click="formChangePassword()" :disabled="isFormEdit">Change Password</v-btn>
                        </v-col>
                        <v-col cols="12" sm="6" class="text-center text-sm-right" order="1" order-sm="2">
                          <v-fade-transition hide-on-leave>
                            <v-btn small :block="$vuetify.breakpoint.xs" class="white--text" color="mou-highlight" 
                              @click="formEdit()" v-if="!isFormEdit && !isChangePassword"
                            >
                              <v-icon left small>mdi-pencil</v-icon>Edit
                            </v-btn>
                          </v-fade-transition>

                          <v-fade-transition hide-on-leave>
                            <v-btn small :block="$vuetify.breakpoint.xs" color="grey" dark @click="formCancel()" v-if="isFormEdit">
                              <v-icon left small>mdi-close</v-icon>Cancel
                            </v-btn>
                          </v-fade-transition>

                          <v-fade-transition hide-on-leave>
                            <v-btn small :block="$vuetify.breakpoint.xs" color="success" dark @click="formSave()" v-if="isFormEdit"
                              :class="$vuetify.breakpoint.xs ? 'mt-2' : 'ml-2'"
                            >
                              <v-icon left small>mdi-check</v-icon>Save
                            </v-btn>
                          </v-fade-transition>

                        </v-col>
                      </v-row>  
                    </v-fade-transition>

                    <v-slide-y-transition hide-on-leave>
                      <v-row class="mt-10" v-if="isChangePassword">
                        <v-col cols="12" class="pb-0 mou-subtitle fw-500 pb-0">Change Password</v-col>
                        <v-divider class="mt-3"  v-if="isChangePassword"></v-divider>

                    
                        <v-col cols="12" class="mou-caption-2 fw-500 pb-0">
                          Old Password
                        </v-col>
                        <v-col cols="12">
                          <v-text-field  hide-details="auto" solo
                            v-model="formPassword.oldPassword"
                            :append-icon="showOldPassword ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="showOldPassword ? 'text' : 'password'"
                            :rules="passwordRules"
                            name="input-10-1"
                            label="Old Password"
                            @click:append="showOldPassword = !showOldPassword"
                          ></v-text-field>
                        </v-col>
                      

                        <v-col cols="12" class="mou-caption-2 fw-500 pb-0">
                          New Password
                        </v-col>
                        <v-col cols="12">
                          <v-text-field  hide-details="auto" solo
                            v-model="formPassword.newPassword"
                            :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="showNewPassword ? 'text' : 'password'"
                            :rules="passwordRules"
                            name="input-10-1"
                            label="New Password"
                            @click:append="showNewPassword = !showNewPassword"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" class="text-right">
                          <v-btn small :block="$vuetify.breakpoint.xs" color="grey" class="mr-2" dark @click="formChangePasswordCancel()" v-if="isChangePassword">
                            <v-icon left small>mdi-close</v-icon>Cancel
                          </v-btn>
                          <v-btn small :block="$vuetify.breakpoint.xs" color="success" dark @click="formChangePasswordSave()" v-if="isChangePassword"
                            :class="$vuetify.breakpoint.xs ? 'mt-2' : 'ml-2'"
                          >
                            <v-icon left small>mdi-check</v-icon>Change
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-slide-y-transition>


                    





                  </v-col>
                </v-row>
            </v-flex>

          </v-layout>
        </v-col>
      </v-row>
    </v-form>

  </v-container>
</template>

<script>
  import { mapState, mapActions } from 'vuex'

  export default {
    data: () => ({
      isLoadingData: false,
      isFormValid: true,
      isFormEdit: false,
      isChangePassword: false,
      snackbar: { show: false, color: '', message: ''},

      showOldPassword: false,
      showNewPassword: false,

      formData: {
        id: null,
        name: null,
        email: null,
      },

      formPassword: {
        id: null,
        oldPassword: null,
        newPassword: null,
      },

      nameRules: [ 
        v => (!!v) || 'Name is required', 
        v => (v && !!v.trim()) || 'Name is required', 
        v => (v && v.trim().length > 4) || 'Name must be more than 4 characters'
      ],
      emailRules: [ 
        v => !!v || 'E-mail is required', 
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ],
      passwordRules: [ 
        v => !!v || 'Password is required', 
        v => (v && v.length > 4) || 'Password must be more than 4 characters'
      ],
    }),

    computed: {
      ...mapState({
        profile: state => state.backend_profile.profile,
      }),
    },

    mounted(){
      this.isLoadingData = true;
      this.getProfile().then((response) => {
        if(response.errors){
          this.callSnackbar('error',response.errors);
          return;
        }
        this.initVariable();
        this.isLoadingData = false;
      });
    },

    methods: {
      ...mapActions({
        getProfile: 'backend_profile/getProfile'
      }),

      initVariable(){
        this.formData.id = null;
        this.formData.name = null;
        this.formData.email = null;

        this.formData.id = this.profile.id;
        this.formData.name = this.profile.name;
        this.formData.email = this.profile.email;
      },

      formEdit(){
        this.isFormEdit = true;
      },

      formChangePassword(){
        this.formPassword.oldPassword = null;
        this.formPassword.newPassword = null;
        this.isChangePassword = true;
      },

      formSave(){
        const incoming = JSON.stringify(this.formData);
        const current = JSON.stringify(this.profile);
        if(incoming === current){
          this.isFormEdit = false;
          return;
        }

        this.isLoadingData = true;
        if(this.$refs.form.validate()){
          this.$store.dispatch('backend_profile/updateProfile', this.formData).then((data) => {
            this.isLoadingData = false;
            this.isFormEdit = false;
            if(data.errors){
              this.callSnackbar('error',data.errors);
              return;
            }
            this.callSnackbar('success',data.success);
          });
          return;
        }

        this.callSnackbar('error','Please fill all the required field');
        this.isLoadingData = false;
      },

      formCancel(){
        this.initVariable();
        this.isFormEdit = false;
      },

      formChangePasswordSave(){
        this.isLoadingData = true;
        if(this.$refs.form.validate()){
          this.$store.dispatch('backend_profile/updatePassword', this.formPassword).then((data) => {
            this.isLoadingData = false;
            if(data.errors){
              this.callSnackbar('error',data.errors);
              return;
            }
            this.callSnackbar('success',data.success);
            this.isChangePassword = false;
          });
          return;
        }

        this.callSnackbar('error','Please fill all the required field');
        this.isLoadingData = false;
      },

      formChangePasswordCancel(){
        this.initVariable();
        this.isChangePassword = false;
      },

      callSnackbar(color,message){
        this.snackbar.message = message;
        this.snackbar.color = color;
        this.snackbar.show = true;
      }

    }

  }
</script>